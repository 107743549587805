import React, { useState, useEffect } from "react";
import {
    View,

} from "@aws-amplify/ui-react";
import * as formik from 'formik';
import * as yup from 'yup';
import { Col, Row, Form, Button, Modal } from "react-bootstrap";
import { getCurrentUser, fetchUserAttributes, updateUserAttributes,confirmUserAttribute,sendUserAttributeVerificationCode } from 'aws-amplify/auth';
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
export const ChangeEmail = () => {
    const { Formik } = formik;
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [userInfo, setUserInfo] = useState("");
    const [band, setBand] = useState(false);
    const [userInfoValidate, setUserInfoValidate] = useState("");
    const [inputValue, setInputValue] = useState('');

    const handleChangeCode = (event) => {
        let values;
        values = event.target.value.replace(/[^0-9]/g, '');
        setInputValue(values);

    };

    const schema = yup.object().shape({

        Email: yup.string().email("Correo no valido").required("Este campo es obligatorio"),
    });

    const handleValidarCorreo = (email) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "email": email
        });
        console.log(raw);
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://cqw12h1x45.execute-api.us-east-1.amazonaws.com/test/validate-email", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if(result.message === "Este email ya está en uso"){
                    toast.error("Este correo ya está en uso", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }else{
                    handleUpdateProfile({
                       email: email
                   });
                }
            })
            .catch((error) => console.error(error));
    }
    useEffect(() => {
        const obtenerInfo = async () => {
            const user = await fetchUserAttributes();
            setUserInfo(user.email.toUpperCase())
            setUserInfoValidate(user)
            console.log(user)
        }
        obtenerInfo()
    }, [band])
    const sendVerificationCodeToNewEmail = async () => {
        try {
            await sendUserAttributeVerificationCode({ userAttributeKey: 'email' });
            toast.success("Código enviado con éxito", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } catch (error) {
            console.error('Error al enviar el código de verificación:', error);
        }
    };

    const handleUpdateProfile = async (userAttributes) => {
        try {
            // Obtener el usuario actual
            const user = await getCurrentUser();

            if (!user) {
                throw new Error('No hay usuario autenticado.');
            }
            /* handleSignIn({username: user.username , password: "Leyva126550$" }) */
            console.log(userAttributes)
            console.log(user)
            // Actualizar los atributos del usuario
            const updateResults = await updateUserAttributes({ userAttributes });

            console.log('Resultados de la actualización:', updateResults);
            handleShow()
            setBand(!band)
        } catch (error) {
            console.error('Error al actualizar los datos del usuario:', error);
            toast.error('Ocurrió un error al actualizar los datos del perfil.', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    };

    const handleConfirmAttribute = async ({ userAttributeKey, confirmationCode }) => {

        try {
            await confirmUserAttribute({ userAttributeKey, confirmationCode })

            console.log("Resultados de la confirmacion")
            handleClose()
            toast.success("Correo verificado con éxito", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setBand(!band)
            navigate("/Configuracion")
        } catch (error) {
            console.error('Error al actualizar los datos del usuario:', error);
            toast.error(error.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    return (
        <>
            <div className="FormTramite">
                <View className="FormTitle">Configuración de Cuenta</View>
                <View className='FormDescripcion'>Actualiza tu información</View>
                <Row className='mb-0'>
                    <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
                        <button type="button" onClick={() => navigate("/Configuracion")}
                            className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                            <FaArrowLeft /> Volver al Inicio
                        </button>
                    </Form.Group>

                    <Form.Group as={Col} md="6">
                    </Form.Group>
                    <Form.Group className='d-grid gap-2' as={Col} md="3">
                    </Form.Group>
                </Row>
                <Formik
                    validationSchema={schema}
                    initialValues={{

                        Email: "",


                    }
                    }
                    onSubmit={async (valores, { resetForm }) => {
                        console.log("Valores", valores);
                        const userAu = await fetchUserAttributes();
                        console.log(userAu);
                        handleValidarCorreo(valores.Email)
                        

                    }
                    }
                >
                    {({ handleSubmit, handleChange, values, touched, errors }) => (

                        <Form onSubmit={handleSubmit} noValidate className='ViewActa mt-3'>
                            <Row className="mb-3">
                                <Form.Label>Correo Actual</Form.Label>
                                <Form.Control

                                    defaultValue={userInfo}
                                    readOnly
                                    disabled

                                />
                            </Row>
                            <Row className="mb-3">
                                <Form.Group as={Col} className="position-relative">
                                    <Form.Label>Correo Electrónico Nuevo</Form.Label>
                                    <Form.Control
                                        name="Email"
                                        type="email"
                                        value={values.Email}
                                        onChange={handleChange}
                                        isValid={touched.Email && !errors.Email}
                                        isInvalid={touched.Email && !!errors.Email}
                                    />
                                    
                                    {userInfoValidate.email_verified === "false" &&
                                        <Button variant="link" onClick={handleShow}>Verificar Correo</Button>

                                    }
                                    <Form.Control.Feedback type="invalid" tooltip>{errors.Email}</Form.Control.Feedback>
                                </Form.Group>

                            </Row>

                            <Row className="mb-3">
                                <Button type="submit" style={{ width: "50%", margin: "auto" }} variant="secondary">Guardar cambios</Button>
                            </Row>
                        </Form>

                    )}


                </Formik>
            </div>
            <Modal show={show} >

                <Modal.Body>
                    <Row className="mb-3" style={{ display: "flex", margin: "auto", textAlign: "center" }}>
                        <Form.Label>Hemos enviado un código de verificacíon a tu correo electrónico por favor ingresalo en el siguiente campo.</Form.Label>
                    </Row>

                    <Row className="mb-3">
                        <Form.Group className="position-relative" as={Col}>
                            <Form.Label>Ingrese el Código de Verificación</Form.Label>

                            <Form.Control
                                name="code"
                                value={inputValue}
                                onChange={(e) => handleChangeCode(e)}
                            />
                            {/* <Button onClick={() => sendVerificationCodeToNewEmail()} variant="link">Reenviar Código</Button> */}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Button variant="secondary" onClick={() => handleConfirmAttribute({
                            userAttributeKey: "email",
                            confirmationCode: inputValue
                        })}>CONFIRMAR</Button>
                        <Button variant="link" onClick={handleClose}>CANCELAR</Button>
                    </Row>
                </Modal.Body>

            </Modal>
            <ToastContainer />
        </>
    )
}
