import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Box from '@mui/material/Box';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { generateClient } from '@aws-amplify/api';
import Row from 'react-bootstrap/Row';
import { InputGroup } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { publishMessage } from '../../graphql/mutations';
import * as yup from 'yup';
import { Button as ButtonB } from 'react-bootstrap';
import ResumenCard from './ResumenCard';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import FormPagos from '../FormPagos';
import { onMessageReceived } from '../../graphql/subscriptions';
import Swal from 'sweetalert2'
import NameFieldValid from './NameFieldValid';
import { useTramiteDataGeneral } from '../../TramiteContext'
import { Backdrop, CircularProgress } from '@mui/material';
import {
    Button,
    Loader,
    View,
} from "@aws-amplify/ui-react";
import DeclaracionesAnt from './DeclaracionesAnt';
import ModalActions from './ModalActions';
import Iframe from 'react-iframe';
import imagenTarjeta from '../../assets/mensaje_tarjeta.png'
import PantallaExitoImpuestos from '../PantallaExitoImpuestos';
import FormEvo from '../FormEvo';

const steps = ['Información', 'Pago'];
const tipo = {
    PRESUPUESTO: 'presupuesto',
    RECIBO: 'Recibo',
    PRE: 'precalculo',
    REFERENCIA: 'pago2',
    PAGO: 'pago1'
}
const client = generateClient();
export const FormRentaInmuebles = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [metodoPago, setMetodoPago] = useState('pago1');
    const [skipped, setSkipped] = React.useState(new Set());
    const [infoRFC, setInfoRFC] = useState({});
    const [authToken, setAuthToken] = useState('')
    const [taskToken, setTaskToken] = useState('')
    const { data, updateData, setCalculo, setDeclaracion, calculo } = useTramiteDataGeneral()
    const [calculoData, setCalculoData] = useState({})
    const [loadingRFC, setLoadingRFC] = useState(false);
    const [loadingPago, setLoadingPago] = useState(false);
    const [declaracionesImp, setDeclaracionesImp] = useState([]);
    const [loading, setLoading] = useState(false);
    const [idForm, setIdForm] = useState('Not Assign');
    const [recalc, setRecalc] = useState(false)
    const [recalcRFC, setRecalcRFC] = useState(false)
    const [reDecs, setReDecs] = useState(false)
    const [pagoExitoModal, setPagoExitoModal] = useState(false)
    const [pagoFallidoModal, setPagoFallidoModal] = useState(false)
    const REACT_APP_HOME = process.env.REACT_APP_HOME;
    const [errorMessage, setErrorMessage] = useState('')

    //nuevas consultas
    const [payloadDeclaraciones, setPayloadDeclaraciones] = useState(null)
    const [payloadCalculo, setPayloadCalculo] = useState(null)
    const [payloadPago, setPayloadPago] = useState(null)
    const SEI_API_ENDPOINT = process.env.REACT_APP_SEI_API_ENDPOINT || "https://gx7mvl9zu7.execute-api.us-east-1.amazonaws.com/test/servicios"
    const [descuento, showDescuento] = useState(false)
    const [descuentoValor, setDescuentoValor] = useState('')
    const [tipoAccion, setTipoAccion] = useState(tipo.PRE)
    const [decLimiteAlcanzado, setDecLimiteAlcanzado] = useState(false)
    const [candadoFechaAnual, setCandadoFechaAnual] = useState(false)
    const [ligaPagoMit, setLigaPagoMit] = useState(null)
    const [callFinal, setCallFinal] = useState(null)
    const [advertenciaPago, setAdvertenciaPago] = useState(null)
    const [continuarPago, setContinuarPago] = useState(false)
    const [pantallaExito, setPantallaExito] = useState(false)
    const [respuestaPago, setRespuestaPago] = useState({});
    const [respuestaRecibo, setRespuestaRecibo] = useState({})
    const [sessionId, setSessionId] = useState(null);
    const [versionId, setVersionId] = useState(null);


    let nombresMeses = [
        "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];


    const handleNext = async () => {
        await generateID();
        let newSkipped = skipped;
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const validationSchema = yup.object().shape({
        RFC: yup.string().required("Este Campo es Obligatorio").matches(/^([A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/, 'RFC inválido'),
        anno: yup.number().required("Este Campo es Obligatorio"),
        periodo: yup.string().required("Este Campo es Obligatorio"),
        base: yup.number().required("Este Campo es Obligatorio")
            .min(0, 'Los numeros deben ser positivos')
            .test('decimal-places', 'Debe tener máximo dos decimales', function (value) {
                if (value !== undefined) {
                    return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
                }
                return true;
            }),
        compensacion: yup.number()
            .min(0, 'Los numeros deben ser positivos')
            .test('decimal-places', 'Debe tener máximo dos decimales', function (value) {
                if (value !== undefined) {
                    return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
                }
                return true;
            }),
        multas: yup.number()
            .min(0, 'Los numeros deben ser positivos')
            .test('decimal-places', 'Debe tener máximo dos decimales', function (value) {
                if (value !== undefined) {
                    return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
                }
                return true;
            }),
        // saldoFavor: yup.number()
        //     .min(0, 'Los numeros deben ser positivos')
        //     .test('decimal-places', 'Debe tener máximo dos decimales', function (value) {
        //         if (value !== undefined) {
        //             return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
        //         }
        //         return true;
        //     }),
        // pagosPro: yup.number()
        //     .min(0, 'Los numeros deben ser positivos')
        //     .test('decimal-places', 'Debe tener máximo dos decimales', function (value) {
        //         if (value !== undefined) {
        //             return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
        //         }
        //         return true;
        //     }),
        // retencion: yup.number()
        //     .min(0, 'Los numeros deben ser positivos')
        //     .test('decimal-places', 'Debe tener máximo dos decimales', function (value) {
        //         if (value !== undefined) {
        //             return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
        //         }
        //         return true;
        //     }),
    });

    const formik = useFormik({
        validationSchema,
        onSubmit: values => {
            handleNext()
        },
        initialValues: {
            RFC: '',
            periodo: '',
            anno: '',
            base: '',
            compensacion: '',
            multas: '',
            // saldoFavor: '',
            // pagosPro: '',
            // retencion: '',

        }

    });
    const resetFormManual = async () => {

        formik.resetForm();
        setReDecs(false)
        setRecalc(false)
        setRecalcRFC(false)
        setCalculo(null)
        setCalculoData(null)
        setDeclaracion(null)
        setTipoAccion(tipo.PRE)
        setDeclaracionesImp([])
        setInfoRFC(null)
        return await generateID();
    }
    const resetFormAnno = (anno) => {
        formik.resetForm();
        setReDecs(false)
        setRecalc(false)
        setTipoAccion(tipo.PRE)
        setCalculo(null)
        setCalculoData(null)
        setDeclaracion(null)
        setDeclaracionesImp([])
        formik.setFieldValue('RFC', infoRFC.rfc)
        formik.setFieldValue('anno', anno)
    }

    const resetFormPeriodo = (per, anno) => {
        formik.resetForm();
        setReDecs(false)
        setRecalc(false)
        setCalculo(null)
        setTipoAccion(tipo.PRE)
        setCalculoData(null)
        setDeclaracion(null)
        setDeclaracionesImp([])
        formik.setFieldValue('RFC', infoRFC.rfc)
        formik.setFieldValue('periodo', per)
        formik.setFieldValue('anno', anno)
    }

    useEffect(() => {
        if (infoRFC?.periodicidad?.tipoPeriodo !== "ANUAL") {
            return
        }
        const fechaActual = new Date();

        // Definir las fechas límite (1 de enero al 17 de febrero)
        const fechaLimiteInicio = new Date(fechaActual.getFullYear(), 0, 1); // 1 de enero
        let diaComparar = 17
        if (new Date(fechaActual.getFullYear(), 1, 17).getDay === 6) {
            diaComparar = 19
        }
        if (new Date(fechaActual.getFullYear(), 1, 17).getDay === 0) {
            diaComparar = 18
        }
        const fechaLimiteFin = new Date(fechaActual.getFullYear(), 1, diaComparar);   // 17 de febrero

        if (fechaActual >= fechaLimiteInicio && fechaActual <= fechaLimiteFin) {
        } else {
            setCandadoFechaAnual(true)
        }
    }, [infoRFC])

    useEffect(() => {
        if (declaracionesImp && declaracionesImp.length >= 4) {
            setDecLimiteAlcanzado(true)
            setReDecs(false);
        }
    }, [declaracionesImp])

    function calcularSumaTotal() {
        // Verificar si declaracionesImp es un arreglo no vacío
        if (Array.isArray(declaracionesImp) && declaracionesImp.length > 0) {
            // Sumar los valores del campo "total"
            const sumaTotal = declaracionesImp.reduce((acumulador, declaracion) => {
                // Convertir el campo "total" a número antes de sumarlo
                const total = parseFloat(declaracion.total) || 0;
                return acumulador + total;
            }, 0);

            return sumaTotal;
        } else {
            // Si el arreglo está vacío o no es un arreglo, retornar 0
            return 0;
        }
    }

    const validarRFCendpoint = async (RFC) => {
        setLoadingRFC(true)
        if (recalcRFC) {
            const rest = await resetFormManual();
            formik.setFieldValue('RFC', RFC)
        }
        const authEndpoint = `${SEI_API_ENDPOINT}/sei/auth`;
        const validateEndpoint = `${SEI_API_ENDPOINT}/sei/declaraciones/validate`;

        const requestData = {
            rfc: RFC,
            clavePadron: "OUGTBI"
        };

        try {
            // Primera consulta para obtener la autenticación
            const authResponse = await fetch(authEndpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({})
            });

            if (!authResponse.ok) {
                throw new Error(`Error en la primera consulta: ${authResponse.status}`);
            }

            const authData = await authResponse.json();
            setAuthToken(authData.token)
            // Segunda consulta utilizando el token de autenticación obtenido
            const validateResponse = await fetch(validateEndpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authData.token}`
                },
                body: JSON.stringify(requestData)
            });

            if (!validateResponse.ok) {
                const errorData = await validateResponse.json();
                setErrorMessage(errorData.error)
                setPagoFallidoModal(true)
                setRecalcRFC(false)
                throw new Error(`Error en la segunda consulta: ${validateResponse.status}`);
            }

            const validateData = await validateResponse.json();

            // Aquí puedes manejar la respuesta de la segunda consulta (validateData)
            setInfoRFC(validateData.response)
            setPayloadDeclaraciones(validateData.payload)
            setRecalcRFC(true)
            setLoadingRFC(false)
        } catch (error) {
            setLoadingRFC(false)
        }

    }
    const declaracionesEndpoint = async (start = null, end = null, rfcData = null, task = null, authts = null) => {
        let anno = formik.values.anno
        let taskT = task ? task : taskToken
        let authT = authts ? authts : authToken
        const info = rfcData ? rfcData : infoRFC
        let perio = end ? end : formik.values.periodo
        if (start && end) {
            formik.setFieldValue("periodo", end)
        }
        const data = formik.values
        let mesinicio = 0;
        let mesfin = 0;
        if (info.periodicidad.tipoPeriodo === "MENSUAL") {
            mesinicio = data.periodo
            mesfin = data.periodo
        }
        if (info.periodicidad.tipoPeriodo === "TRIMESTRAL") {
            mesinicio = Number(data.periodo) - 2
            mesfin = data.periodo
        }
        if (info.periodicidad.tipoPeriodo === "ANUAL" || (start && end)) {
            mesinicio = 1
            mesfin = 12
            const an = new Date().getFullYear()
            formik.setFieldValue("anno", an)
            anno = an
        }
        const endpoint = `${SEI_API_ENDPOINT}/sei/declaraciones/complementarias`;
        setLoading(true)
        setLoadingPago(false);
        const requestData = {
            padron: {
                id: info.padronesDTO.id,
                nombrePadron: info.padronesDTO.nombre,
                clave: "OUGTBI"
            },
            periodicidad: {
                id: info.periodicidad.id,
                tipoPeriodo: info.periodicidad.tipoPeriodo,
                valor: info.periodicidad.valor
            },
            mdCaracteristicaId: `${infoRFC.mdCaracteristicasImpuestosId}`, // Reemplaza "{{mdCaracteristicaId}}" con el valor real
            rfc: `${infoRFC.rfc}`, // Reemplaza "{{rfc}}" con el valor real
            annio: anno,
            mesInicio: validateField(Number(mesinicio), false),
            mesFin: validateField(Number(mesfin), false)
        };

        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`
                },
                body: JSON.stringify(requestData)
            });
            setLoading(false)
            if (!response.ok) {
                const errorData = await response.json();
                setErrorMessage(errorData.error)
                setPagoFallidoModal(true)
                throw new Error(`Error en la consulta: ${response.status}`);
            }

            const responseData = await response.json();
            console.log(responseData);
            responseData.response.declaracionesImpPortal.sort(function (a, b) {
                return a.tipoDeclaracion - b.tipoDeclaracion;
            });
            // Aquí puedes manejar la respuesta de la consulta (responseData)
            setDeclaracion(responseData.response.declaracionesImpPortal)
            setDeclaracionesImp(responseData.response.declaracionesImpPortal)
            setPayloadCalculo(responseData.payload)
            setReDecs(true)
            if (responseData.response.descuentosImpPortal.length > 0) {
                if (responseData.response.descuentosImpPortal[0].referenciaEdoCTA !== null) {
                    setDescuentoValor(responseData.response.descuentosImpPortal[0].referenciaEdoCTA)
                    showDescuento(true)
                }
            }
            setLoading(false)
        } catch (error) {
            console.error("Error en la consulta:", error.message);
        }
    }
    const calcularServicioSei = async (generarEstado = false) => {
        console.log()
        setLoading(true)
        const data = formik.values
        let mesinicio = 0;
        let mesfin = 0;
        if (infoRFC.periodicidad.tipoPeriodo === "MENSUAL") {
            mesinicio = data.periodo
            mesfin = data.periodo
        }
        if (infoRFC.periodicidad.tipoPeriodo === "TRIMESTRAL") {
            mesinicio = Number(data.periodo) - 2
            mesfin = data.periodo
        }
        if (infoRFC.periodicidad.tipoPeriodo === "ANUAL") {
            mesinicio = 1
            mesfin = 12
        }
        const endpoint = `${SEI_API_ENDPOINT}/sei/declaraciones/calculate`;

        const requestData = {
            padron: {
                id: infoRFC.padronesDTO.id,
                nombrePadron: infoRFC.padronesDTO.nombre,
                clave: "OUGTBI"
            },
            generaEstadoCuenta: generarEstado,
            mdCaracteristicaId: infoRFC.mdCaracteristicasImpuestosId,
            declaraciones: {
                rfc: infoRFC.rfc,
                annio: validateField(data.anno, false),
                mesInicio: validateField(Number(mesinicio), false),
                mesFin: validateField(Number(mesfin), false),
                compensacion: validateField(Number(data.compensacion)),
                baseImpuesto: validateField(Number(data.base)),
                multas: validateField(Number(data.multas)),
                // saldoAFavor: validateField(Number(data.saldoFavor)),
                // pagosProvisionales: validateField(Number(data.pagosPro)),
                // otrosIncentivos: null,
                // numeroEmpleados: null,
                // retenciones: validateField(Number(data.retencion))
            }
        };
        console.log(requestData);
        try {
            const response = await fetch(endpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`
                },
                body: JSON.stringify(requestData)
            });

            if (!response.ok) {
                setLoading(false)
                const errorData = await response.json();
                setErrorMessage(errorData.error)
                setPagoFallidoModal(true)
                throw new Error(`Error en la consulta: ${response.status}`);
            }

            const responseData = await response.json();
            console.log("-----------------", responseData);
            // Manejar la respuesta según tus necesidades
            setCalculo({ ...responseData.response, baseImpuesto: formik.values.base, otrosIncentivos: formik.values.incentivos })
            setCalculoData(responseData.response)
            setPayloadPago(responseData.payload)
            setRecalc(true)

            if (!generarEstado) {
                setLoading(false)
            }
        } catch (error) {
            console.error("Error en la consulta:", error.message);
        }
    };
    const generarTransaccion = async (tipoP) => {
        console.log("TRANS", tipoP);
        setLoadingPago(true)
        const user = await fetchUserAttributes();
        try {
            const transaccionEndpoint = `${SEI_API_ENDPOINT}/sei/putEvent`;
            const conceptos = calculoData.conceptos?.map(cp => {
                return { clave: cp.clave, concepto: cp.descripcion, importe: cp.importe }
            })
            const data = formik.values
            let mesinicio = 0;
            let mesfin = 0;
            let anno = formik.values.anno

            if (infoRFC.periodicidad.tipoPeriodo === "MENSUAL") {
                mesinicio = data.periodo
                mesfin = data.periodo
            }
            if (infoRFC.periodicidad.tipoPeriodo === "TRIMESTRAL") {
                mesinicio = Number(data.periodo) - 2
                mesfin = data.periodo
            }
            if (infoRFC.periodicidad.tipoPeriodo === "ANUAL") {
                mesinicio = 1
                mesfin = 12
                const an = new Date().getFullYear()
                formik.setFieldValue("anno", an)
                anno = an
            }
            let tipoDec = ""
            if (declaracionesImp.length > 0 && declaracionesImp[0].id === null) {
                tipoDec = "Normal"
            } else {
                // Obtener la longitud del arreglo
                const longitudArreglo = declaracionesImp.length;
                tipoDec = "Complementaria " + longitudArreglo
            }
            const response = await fetch(transaccionEndpoint, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authToken}`
                },
                body: JSON.stringify({
                    id: idForm,
                    payload: {
                        data: {
                            rfc: infoRFC.rfc,
                            email: user.email,
                            clavePadron: "OUGTBI",//infoRFC.padronesDTO.id,
                            padron: {
                                nombrePadron: infoRFC.padronesDTO.nombre
                            },
                            tipoDeclaracion: tipoDec,
                            user: user.name !== undefined ? user.name : "Contribuyente",
                            estadoCuenta: {
                                serie: calculo.estadoCuenta.serie,
                                identificador: calculo.estadoCuenta.identificador,
                                loteId: calculo.estadoCuenta.loteId,
                                tramiteId: calculo.estadoCuenta.tramiteId,
                                fechaVencimiento: calculo.estadoCuenta.fechaVencimiento,
                                total: calculo.calculos.total
                            },
                            serie: calculo.estadoCuenta.serie,
                            periodicidad: {
                                tipoPeriodo: infoRFC.periodicidad.tipoPeriodo
                            },
                            annio: validateField(anno, false),
                            mesInicio: validateField(Number(mesinicio), false),
                            mesFin: validateField(Number(mesfin), false),
                            conceptos: conceptos,
                            "referencia": `${calculoData.estadoCuenta?.referencias[0].referencia}`,
                            razonSocial: infoRFC.razonSocial,
                            tipoPeriodo: infoRFC.periodicidad.tipoPeriodo
                        },
                        authToken: authToken,
                        userId: user.sub,
                        type: "declaraciones",
                        "validationRequested": true,
                    }
                })
            });

            if (!response.ok) {
                const errorData = await response.json();
                // Manejar el error según tus necesidades
                console.error("Error en la transacción:", errorData.error);
                setLoadingPago(false)
                return;
            }

            const responseData = await response.json();
            // Manejar la respuesta según tus necesidades
            if (tipoP === tipo.PAGO || tipoP === tipo.RECIBO || tipoP === tipo.REFERENCIA) {
                const resp = await GenerarLigaPago(tipoP);
                //setLoading(false)
                return responseData
            }
            //setLoading(false)
            return responseData
        } catch (error) {
            console.error("Error al realizar la transacción:", error.message);
            return error
        }
    }

    const llamarPago = async (tipoP) => {
        if (!infoRFC) {
            return
        }
        procesoPago(tipoP)
    }

    const procesoPago = async (tipoP) => {
        if (tipoP === tipo.PAGO || tipoP === tipo.REFERENCIA ) {
            setLoadingPago(true)
            const resp = await generarTransaccion(tipoP);
            return
        }
        GenerarLigaPago(tipoP)
    }


    useEffect(() => {
        if (tipoAccion === tipo.PAGO || tipoAccion === tipo.RECIBO || tipoAccion === tipo.REFERENCIA || tipoAccion === tipo.PRESUPUESTO) {
            
            setTimeout(async () => {
                await llamarPago(tipoAccion);
            }, 2000);
        }
    }, [calculo])

    function getCurrentDate() {
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0'); // Get the day and pad with leading zero if necessary
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Get the month (0-11) and add 1, then pad with leading zero
        const year = today.getFullYear(); // Get the full year (4 digits)

        return `${day}/${month}/${year}`;
    }

    const validateField = (value, setString = true) => {
        return value === "" || value === 0 ? null : setString ? `${value}` : Number(value)
    }
    const GenerarLigaPago = async (tipoPago = null) => {
        setLoadingPago(true)
        const user = await fetchUserAttributes();
        // metodoPago : pago1 : card - pago2 : - pago3 :
        // prepara conceptos con lo de calculo
        const opcionPago = tipoPago ? tipoPago : metodoPago
        const conceptos = calculoData.conceptos?.map(cp => {
            return { clave: cp.clave, concepto: cp.descripcion, importe: cp.importe }
        })
        //preparar otras referencias
        const otrasRef = calculoData.estadoCuenta?.referencias?.map(referencia => {
            const partes = referencia.recaudacion.split('-');
            const parteDespuesDelGuion = partes.length > 1 ? partes[1] : null;
            const convenio = parteDespuesDelGuion
            return { banco: referencia.recaudacion, convenio: convenio ? convenio : '', referencia: referencia.referencia }
        })
        let mesinicio = 0;
        let mesfin = 0;
        if (infoRFC.periodicidad.tipoPeriodo === "MENSUAL") {
            mesinicio = formik.values.periodo
            mesfin = formik.values.periodo
        }
        if (infoRFC.periodicidad.tipoPeriodo === "TRIMESTRAL") {
            mesinicio = Number(formik.values.periodo) - 2
            mesfin = formik.values.periodo
        }
        if (infoRFC.periodicidad.tipoPeriodo === "ANUAL") {
            mesinicio = 1
            mesfin = 12
        }
        let tipoDec = ""
        if (declaracionesImp.length > 0 && declaracionesImp[0].id === null) {
            tipoDec = "Normal"
        } else {
            // Obtener la longitud del arreglo
            const longitudArreglo = declaracionesImp.length;
            tipoDec = "Complementaria " + longitudArreglo
        }
        const variables = opcionPago === "pago1" ?
            {
                "id": idForm,
                "payload": {
                    "paymentMethod": "evo",
                    "email": user.email,
                    "user": user.name !== undefined ? user.name : "Contribuyente",
                    "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                    "reference": `${calculoData.estadoCuenta?.referencias[0].referencia}`,//"202300011471739766260",
                    "monto": `${calculoData.calculos?.total}`,//"0.01",
                    type: "declaraciones",
                }
            }
            :
            opcionPago === "pago2" || opcionPago === "pago3" || opcionPago === "pago4" ?
                {
                    "id": idForm,
                    "payload": {

                        "paymentMethod": "spei",
                        "data": {
                            "loteId": `${calculoData.estadoCuenta?.loteId}`,
                            "total_pagar": calculoData.calculos?.total,
                            "user": user.name !== undefined ? user.name : "Contribuyente",
                            "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                            // "movimiento": "Impuesto por Otorgar el Uso o Goce Temporal de Bienes Inmuebles",
                            "rfc": infoRFC.rfc,
                            "fecha_generacion": getCurrentDate(),
                            "fecha_vigencia": `${formatearFecha(calculoData.estadoCuenta?.fechaVencimiento)}`,//"19/10/2022",
                            "contribuyente": infoRFC?.rfc?.length === 13 ? infoRFC.nombre : infoRFC.razonSocial,
                            "referencia": `${calculoData.estadoCuenta?.referencias[0].referencia}`,
                            "otras_referencias": otrasRef,
                            "periodo": infoRFC.periodicidad.tipoPeriodo === "MENSUAL" ? `${nombresMeses[Number(mesinicio) - 1]} ${formik.values.anno}` : `${nombresMeses[Number(mesinicio) - 1]}-${nombresMeses[Number(mesfin) - 1]} ${formik.values.anno}`,

                        },
                        "email": user.email,
                        "procedure": "referencia_pago"
                    }
                }
                : opcionPago === "Recibo" ?
                    {
                        "id": idForm,
                        "payload": {
                            "data": {
                                user: user.name !== undefined ? user.name : "Contribuyente",
                                rfc: infoRFC.rfc,
                                email: user.email,
                                clavePadron: "OUGTBI",//infoRFC.padronesDTO.id,
                                padron: {
                                    nombrePadron: infoRFC.padronesDTO.nombre
                                },
                                tipoDeclaracion: tipoDec,
                                estadoCuenta: {
                                    serie: calculo.estadoCuenta.serie,
                                    identificador: calculo.estadoCuenta.identificador,
                                    loteId: calculo.estadoCuenta.loteId,
                                    tramiteId: calculo.estadoCuenta.tramiteId,
                                    fechaVencimiento: calculo.estadoCuenta.fechaVencimiento
                                },
                                serie: calculo.estadoCuenta.serie,
                                periodicidad: {
                                    tipoPeriodo: infoRFC.periodicidad.tipoPeriodo
                                },
                                annio: validateField(formik.values.anno, false),
                                mesInicio: validateField(Number(mesinicio), false),
                                mesFin: validateField(Number(mesfin), false),
                                conceptos: conceptos,
                                razonSocial: infoRFC.razonSocial,
                                tipoPeriodo: infoRFC.periodicidad.tipoPeriodo,
                                "docData": {
                                    user: user.name !== undefined ? user.name : "Contribuyente",
                                    "loteId": `${calculoData.estadoCuenta?.loteId}`,
                                    "identificador": `${calculoData.estadoCuenta?.identificador}`,
                                    "serie": `${calculoData.estadoCuenta?.serie}`,
                                    "contribuyente": infoRFC?.rfc?.length === 13 ? infoRFC.nombre : infoRFC.razonSocial,
                                    "fecha_generacion": getCurrentDate(),
                                    "fecha_pago": getCurrentDate(),
                                    "estatus": "PAGADO",
                                    // "num_transaccion": calculoData.estadoCuenta?.loteId,
                                    "rfc": infoRFC.rfc,
                                    "referencia": `${calculoData.estadoCuenta?.referencias[0].referencia}`,
                                    "fecha_vigencia": `${formatearFecha(calculoData.estadoCuenta?.fechaVencimiento)}`,//"19/10/2022",
                                    "movimiento": "Impuesto por Otorgar el Uso o Goce Temporal de Bienes Inmuebles",
                                    "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                                    "tramiteId": calculoData.estadoCuenta?.tramiteId,
                                    "instrumento_pago": "referencia bancaria",
                                    "base_impuesto": `${formik.values.base}`,//"5%",
                                    "saldo_favor": `${calculoData.calculos?.saldoAFavor !== undefined ? calculoData.calculos?.saldoAFavor : "0"}`,
                                    "periodo": infoRFC.periodicidad.tipoPeriodo === "MENSUAL" ? `${nombresMeses[Number(mesinicio) - 1]} ${formik.values.anno}` : `${nombresMeses[Number(mesinicio) - 1]}-${nombresMeses[Number(mesfin) - 1]} ${formik.values.anno}`,
                                    "tipo_declaracion": tipoDec,
                                    "otros_conceptos": conceptos,
                                    "total": `${calculoData.calculos?.total}`,
                                }
                            },
                            "email": user.email,
                            "procedure": "recibo_pago",
                            "validationRequested": true,
                            "recalculate": false,
                            "type": "declaraciones",
                            userId: user.sub,
                            authToken,
                            taskToken

                        },
                    }
                    :
                    {
                        "id": idForm,
                        "payload": {
                            "paymentMethod": "spei",
                            "data": {
                                "user": user.name !== undefined ? user.name : "Contribuyente",
                                "contribuyente": infoRFC?.rfc?.length === 13 ? infoRFC.nombre : infoRFC.razonSocial,
                                "fecha_generacion": getCurrentDate(),
                                "rfc": infoRFC.rfc,
                                "fecha_vigencia": `${formatearFecha(calculoData.estadoCuenta?.fechaVencimiento)}`,//"19/10/2022",
                                "movimiento": "Impuesto por Otorgar el Uso o Goce Temporal de Bienes Inmuebles",
                                "tramite": infoRFC.padronesDTO.nombre,//"IMPUESTO A LA EMISION DE CONTAMINANTES AL SUELO, SUBSUELO Y AGUA",
                                "instrumento_pago": "referencia bancaria",
                                "retenciones": `${formik.values.base}`,//"5%",

                                "periodo": infoRFC.periodicidad.tipoPeriodo === "MENSUAL" ? `${nombresMeses[Number(mesinicio) - 1]} ${formik.values.anno}` : `${nombresMeses[Number(mesinicio) - 1]}-${nombresMeses[Number(mesfin) - 1]} ${formik.values.anno}`,
                                "tipo_declaracion": tipoDec,
                                "otros_conceptos": conceptos,
                                "total_pagar": `${calculoData.calculos?.total}`,
                                "monto_anterior": calcularSumaTotal(),
                                "otros_incentivos": `${calculo.otrosIncentivos}`,
                            },
                            "email": user.email,
                            "procedure": "presupuesto_retencion_bienes",
                        },
                    };
        const Data = await client.graphql({
            query: publishMessage,
            variables: {
                ...variables,
                payload: JSON.stringify(variables.payload),
            },
        });
        setLoadingPago(true)
        console.log("Data :>> ", Data, variables);
    }

    const formatearFecha = (fecha) => {
        if (fecha) {
            const date = new Date(fecha);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Sumamos 1 porque los meses van de 0 a 11
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return null;
    };
    const [message, setMessage] = useState(null);
    const generateID = async () => {
        const user = await getCurrentUser();
        setIdForm(`PagoImpuestoBienes_${user.username}_${Date.now()}`)
    }
    useEffect(() => {
        generateID()
    }, [])
    useEffect(() => {
        const subscription = client.graphql({ query: onMessageReceived, variables: { id: idForm } })
            .subscribe({
                next: (event) => {
                    const receivedMessage = event.data.onMessageReceived;
                    const dataRec = JSON.parse(receivedMessage.payload)
                    setMessage(JSON.parse(receivedMessage.payload));
                    console.log("DATA rec", dataRec);
                    if (dataRec.taskToken) {
                        setTaskToken(dataRec.taskToken)
                    }
                    if (dataRec?.error) {
                        console.log("REP1");
                        setErrorMessage(dataRec?.error)
                        setPagoFallidoModal(true)
                        if (dataRec.step === "validarRfc") {
                            setRecalcRFC(false)
                        }
                        //alert("Hubo un error intentalo de nuevo")
                        return
                    }
                    if (dataRec.status === "PAGO VALIDADO") {
                        //activar loader
                        console.log("Validar pago");
                        setRespuestaPago(dataRec)
                        //setLoading(false)
                    }
                    if (dataRec?.procedure === "paymentUrl") {
                        if (dataRec.response.cd_response === "error") {
                            //AlertsAction("error")
                            console.log("REP2");
                            setPagoExitoModal(false)
                            setPagoFallidoModal(true)
                            setLoadingPago(false);
                            setLoading(false)
                            return
                        }
                        if (dataRec.response.cd_response === "success") {
                            //AlertsAction("success")
                            // setPagoExitoModal(true);
                            setPagoFallidoModal(false);
                            /* setLigaPagoMit(dataRec.response.nb_url); */
                            setSessionId(dataRec.response.session.sessionId)
                            setVersionId(dataRec.response.session.version)
                            setLoadingPago(false);
                            setLoading(false);
                          }
                    }
                    if (dataRec.Procedure === "referencia_pago" || dataRec.Procedure === "presupuesto_retencion_bienes" || dataRec.Procedure === "recibo_pago") {
                        if (dataRec.Procedure === "referencia_pago" || dataRec.Procedure === "presupuesto_retencion_bienes") {
                            window.open(dataRec.TXTBody);
                            setLoadingPago(false)
                        }
                        if (dataRec.Procedure === "recibo_pago") {
                            //activar vista de exito
                            setPantallaExito(true)
                            setRespuestaRecibo(dataRec)
                            setLoadingPago(false)
                            setLoading(false)
                            return
                        }
                        if (dataRec.Procedure === "referencia_pago") {
                            setLoadingPago(false)
                            setPagoExitoModal(true)
                            setLoading(false)
                            setPagoFallidoModal(false)
                            return
                        }
                        return
                    }
                },
                error: (error) => {
                    console.error('Subscription error', error);
                },
            });


        return () => {
            subscription.unsubscribe();
        };
    }, [idForm]);

    const handleBlur = field => {
        formik.setFieldTouched(field, true);
    };
    function validarCadena(cadena) {
        const expresionRegular = /^([A-Z\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{3})?$/;

        return expresionRegular.test(cadena);
    }
    const handleRFCChange = (e) => {
        const { name, value } = e.target;

        if (name === "RFC") {
            formik.setFieldValue(name, value.toUpperCase());
            if (value.length === 12 && name === "RFC") {
                if (validarCadena(value)) {

                    validarRFCendpoint(value)
                    formik.values.RFC = value
                } else {
                    formik.values.RFC = value
                    formik.setFieldError(name, "RFC no valido");


                }
            } else if (value.length === 13 && name === "RFC") {
                if (validarCadena(value)) {
                    formik.values.RFC = value
                    validarRFCendpoint(value)
                } else {
                    formik.values.RFC = value
                    formik.setFieldTouched(name, true);


                }

            }
        }

        /* onBlur={() => { handleBlur('RFC'); validarRFCendpoint(formik.values.RFC); }} */

    };
    const validateRFC = (value) => {
        // Asegúrate de que la longitud no supere 15 caracteres
        if (value.length > 15) {
            return value.slice(0, 15); // Truncar la cadena a 15 caracteres
        }
        return value.trim().toUpperCase();
    };
    const generarOpcionesMeses = () => {
        const annoSeleccionado = Number(formik.values.anno) || 2023
        const fechaActual = new Date();
        const mesActual = fechaActual.getMonth(); // Sumamos 1 porque los meses van de 0 a 11 en JavaScript

        // Verificar si el año seleccionado es igual al año actual
        if (annoSeleccionado === fechaActual.getFullYear()) {
            // Si es igual, solo regresar opciones para los meses antes del mes actual
            const opciones = Array.from({ length: mesActual }, (_, index) => (
                <option key={index + 1} value={index + 1}>{obtenerNombreMes(index + 1)}</option>
            ));

            return opciones;
        } else {
            // Si es diferente, regresar todas las opciones
            const opciones = Array.from({ length: 12 }, (_, index) => (
                <option key={index + 1} value={index + 1}>{obtenerNombreMes(index + 1)}</option>
            ));

            return opciones;
        }
    }
    const generarOpcionesTrimestres = () => {
        const annoSeleccionado = Number(formik.values.anno) || 2023
        const fechaActual = new Date();
        const mesActual = fechaActual.getMonth(); // Sumamos 1 porque los meses van de 0 a 11 en JavaScript
        const trimestres = [3, 6, 9, 12];

        // Filtrar trimestres disponibles que sean antes del mes actual
        const trimestresDisponibles = trimestres.filter(trimestre => trimestre < mesActual);

        // Verificar si el año seleccionado es igual al año actual
        if (annoSeleccionado === fechaActual.getFullYear()) {
            // Si es igual, solo regresar opciones para los trimestres disponibles
            const opciones = trimestresDisponibles.map(trimestre => (
                <option key={trimestre} value={trimestre}>
                    {obtenerNombreMes(trimestre - 2)} - {obtenerNombreMes(trimestre)}
                </option>
            ));

            return opciones;
        } else {
            // Si es diferente, regresar todas las opciones
            const opciones = trimestres.map(trimestre => (
                <option key={trimestre} value={trimestre}>
                    {obtenerNombreMes(trimestre - 2)} - {obtenerNombreMes(trimestre)}
                </option>
            ));

            return opciones;
        }
    }
    function obtenerNombreMes(mes) {
        const nombresMeses = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
        return nombresMeses[mes - 1];
    }
    const handleChangeSelect = (event) => {
        const { name, value } = event.target;
        if (name === "anno") {
            formik.setFieldValue(name, value)
            formik.values.anno = value;
            resetFormAnno(formik.values.anno); handleBlur('anno')
        } else {
            formik.setFieldValue(name, value)
            formik.values.periodo = value;

            formik.setFieldTouched(name, true, false); // Tercer argumento a `false` evita la validación hasta que se haga un nuevo cambio

            resetFormPeriodo(formik.values.periodo, formik.values.anno);
            handleBlur('periodo');
            declaracionesEndpoint(value);
        }

        // Puedes realizar otras acciones aquí según tus necesidades */
    };
    const renderPeriodos = (tipo) => {
        if (tipo === "MENSUAL") {
            return (
                <Row className="mb-3">
                    {loading ? (

                        <div >
                            <Loader size='small' emptyColor="lightgray" filledColor="gray" variation='linear' />
                        </div>

                    ) : null}
                    <Form.Group
                        as={Col}
                        controlId="validationFormik102"
                        className="position-relative"
                    >
                        <Form.Label> Año*</Form.Label>
                        <Form.Select
                            type="number"
                            name="anno"
                            value={formik.values.anno}
                            onChange={handleChangeSelect}
                            isValid={
                                formik.touched.anno && !formik.errors.anno
                            }
                            isInvalid={
                                !!formik.errors.anno && formik.touched.anno
                            }
                            disabled={!recalcRFC || loading}
/*                             onBlur={() => { resetFormAnno(formik.values.anno); handleBlur('anno') }}
 */                        >
                            <option key="default" value="">
                                Seleccione...
                            </option>
                            {Array(2).fill(undefined).map((_, index) => (
                                <option key={index} value={new Date().getFullYear() - index}>
                                    {new Date().getFullYear() - index}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" tooltip></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.anno}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        controlId="validationFormik102"
                        className="position-relative"
                    >
                        <Form.Label> Periodo*</Form.Label>
                        <Form.Select
                            name="periodo"
                            value={formik.values.periodo}
                            onChange={handleChangeSelect}
                            isValid={
                                formik.touched.periodo &&
                                !formik.errors.periodo
                            }
                            isInvalid={
                                !!formik.errors.periodo && formik.touched.periodo
                            }
                            disabled={!recalcRFC || formik.values.anno === '' || loading}
/*                             onBlur={() => { resetFormPeriodo(formik.values.periodo, formik.values.anno); handleBlur('periodo'); declaracionesEndpoint(); }}
 */                        >
                            <option>Seleccione...</option>
                            {generarOpcionesMeses()}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.periodo}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            )
        }

        if (tipo === "TRIMESTRAL") {
            return (
                <Row className="mb-3">
                    {loading ? (

                        <div >
                            <Loader size='small' emptyColor="lightgray" filledColor="gray" variation='linear' />
                        </div>

                    ) : null}
                    <Form.Group
                        as={Col}
                        controlId="validationFormik102"
                        className="position-relative"
                    >
                        <Form.Label> Año*</Form.Label>
                        <Form.Select
                            type="number"
                            name="anno"
                            value={formik.values.anno}
                            onChange={handleChangeSelect}
                            isValid={
                                formik.touched.anno && !formik.errors.anno
                            }
                            isInvalid={
                                !!formik.errors.anno && formik.touched.anno
                            }
                            disabled={!recalcRFC || loading}
                            onBlur={() => { resetFormAnno(formik.values.anno); handleBlur('anno') }}
                        >
                            <option key="default" value="">
                                Seleccione...
                            </option>
                            {Array(2).fill(undefined).map((_, index) => (
                                <option key={index} value={new Date().getFullYear() - index}>
                                    {new Date().getFullYear() - index}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" tooltip></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.anno}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                        as={Col}
                        controlId="validationFormik102"
                        className="position-relative"
                    >
                        <Form.Label> Periodo*</Form.Label>
                        <Form.Select
                            name="periodo"
                            value={formik.values.periodo}
                            onChange={handleChangeSelect}
                            isValid={
                                formik.touched.periodo &&
                                !formik.errors.periodo
                            }
                            isInvalid={
                                !!formik.errors.periodo && formik.touched.periodo
                            }
                            disabled={!recalcRFC || formik.values.anno === '' || loading}
                            onBlur={() => { resetFormPeriodo(formik.values.periodo, formik.values.anno); declaracionesEndpoint(); handleBlur('periodo'); }}
                        >
                            <option>Seleccione...</option>
                            {generarOpcionesTrimestres()}
                        </Form.Select>

                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.periodo}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            )
        }

        if (tipo === "ANUAL") {
            return (
                <Row className="mb-3">
                    {loading ? (

                        <div >
                            <Loader size='small' emptyColor="lightgray" filledColor="gray" variation='linear' />
                        </div>

                    ) : null}
                    <Form.Group
                        as={Col}
                        controlId="validationFormik102"
                        className="position-relative"
                    >
                        <Form.Label> Año*</Form.Label>
                        <Form.Select
                            type="number"
                            name="anno"
                            value={formik.values.anno}
                            onChange={handleChangeSelect}
                            isValid={
                                formik.touched.anno && !formik.errors.anno
                            }
                            isInvalid={
                                !!formik.errors.anno && formik.touched.anno
                            }
                            disabled={recalcRFC || recalc || loading}
                            onBlur={() => { handleBlur('anno') }}
                        >
                            <option key="default" value="">
                                Seleccione...
                            </option>
                            <option key="anual" value={new Date().getFullYear()}>
                                {new Date().getFullYear()}
                            </option>

                        </Form.Select>
                        <Form.Control.Feedback type="invalid" tooltip></Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors.anno}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Row>
            )
        }

        return (<div className='w-full flex justify-center text-sm text-gray-300'>No hay periodos correspondinetes</div>)
    }
    return (
        <>
            <div className='HeaderTramite'>
                <View className='FormTitle'>Pago al Impuesto por Otorgar el Uso o Goce Temporal de Bienes Inmuebles</View>
                <View className='FormDescripcion'>
                    Este trámite consiste en el pago del impuesto estatal que se genera al obtener ingresos por el
                    arrendamiento de bienes inmuebles que se encuentren ubicados dentro del territorio del Estado
                    de Durango.

                </View>
            </div>
            <View className='ViewPrincipal'>

                {
                    pantallaExito ?
                        <PantallaExitoImpuestos pago={respuestaPago} recibo={respuestaRecibo} titulo={'Impuesto por Otorgar el Uso o Goce Temporal de Bienes Inmuebles'} />
                        :
                        sessionId ?
                            <View >
                                <div className='w-full flex justify-end'><img width={'300px'} height={'150px'} src={imagenTarjeta} alt="Mensaje tarjeta" /></div>
                                <FormEvo sessionId={sessionId} versionId={versionId}/>
                               {/*  <Iframe
                                    src={ligaPagoMit}
                                    height="850px"
                                    width={isMobile ? "99%" : "550px"}
                                    display="initial"
                                    styles={{ overflow: "hidden" }}
                                    position="relative"
                                /> */}
                            </View>
                            :
                            <View className='FormContent'>
                                <Form
                                    onSubmit={formik.handleSubmit}
                                    noValidate
                                    className="ViewActa"
                                >
                                    <Box sx={{ width: '100%' }}>
                                        <Stepper activeStep={activeStep}>
                                            {steps.map((label, index) => {
                                                const stepProps = {};
                                                const labelProps = {};
                                                return (
                                                    <Step key={label} {...stepProps}>
                                                        <StepLabel  {...labelProps}>{label}</StepLabel>
                                                    </Step>
                                                );
                                            })}
                                        </Stepper>
                                        {activeStep === steps.length ? (
                                            <React.Fragment>
                                                <Typography sx={{ mt: 2, mb: 1 }}>
                                                    <label>
                                                        Descripcion: Pulsa el boton para generar tu orden de
                                                        pago.
                                                    </label>
                                                </Typography>
                                                <Box
                                                    sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                                                    style={{ justifyContent: "space-between" }}
                                                >
                                                    <Button
                                                        variant="secondary"
                                                        disabled={activeStep === 0}
                                                        onClick={handleBack}
                                                    >
                                                        Anterior
                                                    </Button>

                                                    <Button type="submit" variant="secondary">
                                                        Enviar
                                                    </Button>
                                                </Box>
                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                {activeStep === 0 ? (
                                                    <Box>
                                                        <Col>
                                                            <Row className="mb-3 mt-3">
                                                                <Form.Group
                                                                    as={Col}
                                                                    controlId="validationFormikRFC"
                                                                    className="position-relative"
                                                                >
                                                                    <Form.Label>RFC con Homoclave*</Form.Label>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="RFC"
                                                                            maxLength={13}
                                                                            value={formik.values.RFC}
                                                                            disabled={loading}
                                                                            onChange={handleRFCChange}
/*                                                                             onBlur={() => { handleBlur('RFC'); validarRFCendpoint(formik.values.RFC); }}
 */                                                                            isValid={
                                                                                formik.touched.RFC && !formik.errors.RFC
                                                                            }
                                                                            isInvalid={
                                                                                !!formik.errors.RFC && formik.touched.RFC
                                                                            }
                                                                        />
                                                                        {loadingRFC ? (

                                                                            <div style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}>
                                                                                <Loader size='large' emptyColor="lightgray" filledColor="gray" />
                                                                            </div>

                                                                        ) : null}


                                                                    </div>
                                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                                        {formik.errors.RFC}
                                                                    </Form.Control.Feedback>

                                                                    {infoRFC?.rfc?.length === 13 && infoRFC?.nombre && (<NameFieldValid nombre={infoRFC?.nombre} domicilio={`${infoRFC.calle} #${infoRFC.numeroExterior}, ${infoRFC.colonia}, C.P. ${infoRFC.codigoPostal}, ${infoRFC.localidad}, ${infoRFC.municipio} `} />)}
                                                                    {infoRFC?.rfc?.length === 12 && infoRFC?.razonSocial && (<NameFieldValid nombre={infoRFC?.razonSocial} domicilio={`${infoRFC.calle} #${infoRFC.numeroExterior}, ${infoRFC.colonia}, C.P. ${infoRFC.codigoPostal}, ${infoRFC.localidad}, ${infoRFC.municipio} `} />)}
                                                                </Form.Group>
                                                            </Row>
                                                            {renderPeriodos(infoRFC?.periodicidad?.tipoPeriodo)}
                                                            {/***DECLARACIONES ANterkiores */}

                                                            {/****DECLARACIONES RENDER */}
                                                            <DeclaracionesAnt className='' tipo='bienes' />
                                                            <Row className='mb-3'>
                                                                <Form.Group
                                                                    as={Col}
                                                                    controlId="validationFormik102"
                                                                    className="position-relative"
                                                                >
                                                                    <Form.Label > Base del Impuesto*</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="base"
                                                                        value={formik.values.base}
                                                                        onChange={formik.handleChange}
                                                                        isValid={formik.touched.base && !formik.errors.base}
                                                                        isInvalid={!!formik.errors.base && formik.touched.base}
                                                                        disabled={!reDecs || loading}
                                                                        onBlur={() => { handleBlur('base'); setTipoAccion(tipo.PRE); calcularServicioSei(); }}
                                                                    />

                                                                    <Form.Control.Feedback type="invalid" tooltip>{formik.errors.base}</Form.Control.Feedback>
                                                                </Form.Group>

                                                            </Row>
                                                            <Row className='mb-3'>
                                                                <Form.Group
                                                                    as={Col}
                                                                    controlId="validationFormik102"
                                                                    className="position-relative"
                                                                >
                                                                    <Form.Label > Compensacion</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="compensacion"
                                                                        value={formik.values.compensacion}
                                                                        onChange={formik.handleChange}
                                                                        isValid={formik.touched.compensacion && !formik.errors.compensacion}
                                                                        isInvalid={!!formik.errors.compensacion && formik.touched.compensacion}
                                                                        disabled={formik.values.base === '' || loading}
                                                                        onBlur={() => { handleBlur('compensacion'); setTipoAccion(tipo.PRE); calcularServicioSei(); }}
                                                                    />

                                                                    <Form.Control.Feedback type="invalid" tooltip>{formik.errors.compensacion}</Form.Control.Feedback>
                                                                </Form.Group>

                                                            </Row>
                                                            <Row className='mb-3'>
                                                                <Form.Group
                                                                    as={Col}
                                                                    controlId="validationFormik102"
                                                                    className="position-relative"
                                                                >
                                                                    <Form.Label > Multas</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        name="multas"
                                                                        value={formik.values.multas}
                                                                        onChange={formik.handleChange}
                                                                        isValid={formik.touched.multas && !formik.errors.multas}
                                                                        isInvalid={!!formik.errors.multas && formik.touched.multas}
                                                                        disabled={formik.values.base === '' || loading}
                                                                        onBlur={() => { handleBlur('multas'); setTipoAccion(tipo.PRE); calcularServicioSei(); }}
                                                                    />

                                                                    <Form.Control.Feedback type="invalid" tooltip>{formik.errors.multas}</Form.Control.Feedback>
                                                                </Form.Group>

                                                            </Row>


                                                        </Col>
                                                        <Col>

                                                        </Col>
                                                    </Box>
                                                ) : (
                                                    activeStep === 1 && (
                                                        <FormPagos
                                                            metodoPago={metodoPago}
                                                            setMetodoPago={setMetodoPago}
                                                            generarPresupuesto={() => {
                                                                //GenerarLigaPago("presupuesto") 
                                                                setTipoAccion(tipo.PRESUPUESTO)
                                                                calcularServicioSei();
                                                            }}
                                                            continuarPago={setContinuarPago}
                                                        />
                                                    )
                                                )}
                                            </React.Fragment>
                                        )}
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                        {
                                            activeStep === steps.length - 1 &&
                                            <ButtonB
                                                variant='secondary'
                                                disabled={activeStep === 0}
                                                onClick={handleBack}


                                            >
                                                Anterior
                                            </ButtonB>
                                        }
                                        <Box sx={{ flex: '1 1 auto' }} />
                                        {
                                            activeStep === steps.length - 1 && <ButtonB
                                                onClick={() => {
                                                    setTipoAccion(tipo.PRESUPUESTO)
                                                    // setLoadingPago(true)
                                                    calcularServicioSei(true);
                                                }}
                                                variant='secondary' className='mr-3 ml-3'>
                                                Descargar Presupuesto
                                            </ButtonB>
                                        }
                                        <ButtonB onClick={async () => {
                                            if (activeStep === steps.length - 1) {
                                                /*setTipoAccion(tipo.PRESUPUESTO)
                                                calcularServicioSei(true);
                                                return;*/
                                                if (metodoPago === "pago1") {
                                                    setTipoAccion(tipo.PAGO)
                                                    setLoadingPago(true);
                                                    //generarTransaccion(tipo.PAGO)
                                                } else {
                                                    setTipoAccion(tipo.REFERENCIA)
                                                    setLoadingPago(true);
                                                    //generarTransaccion(tipo.REFERENCIA)
                                                }
                                                calcularServicioSei(true)
                                                return;
                                            }
                                            if (calculoData?.calculos?.total === 0) {
                                                setAdvertenciaPago(true)
                                                return
                                            }
                                            await handleNext();
                                        }} variant='secondary'
                                            disabled={!recalc || !formik.isValid || (activeStep === steps.length - 1 && continuarPago === false)}
                                        >
                                            {activeStep === steps.length - 1 ? metodoPago === "pago1" ? 'Continuar al pago' : "Descargar referencia de pago" : calculoData?.calculos?.total !== 0 ? 'Siguiente' : 'Realizar pago'}
                                        </ButtonB>
                                    </Box>
                                </Form>
                            </View>
                }
            </View>
            {!pantallaExito && <ResumenCard ligaPagoMit={ligaPagoMit} idForm={idForm} tipo="bienes" desableCalc={!((formik.isValid === true && reDecs === true))} montoAnt={calcularSumaTotal()} />}
            <ModalActions
                title={'Se ha generado la referencia de pago con éxito'}
                content={''} icon={'check'}
                show={pagoExitoModal}
                onHide={() => { setPagoExitoModal(false); }}
                buttonAction={() => { setPagoExitoModal(false); }}
            />
            <ModalActions
                title={'Hubo un problema'}
                content={'por favor, vuelve a intentarlo'} icon={'error'}
                desc={errorMessage}
                show={pagoFallidoModal}
                onHide={() => { setPagoFallidoModal(false); setErrorMessage('') }}
                buttonAction={() => { setPagoFallidoModal(false); setErrorMessage('') }}
            />
            <ModalActions
                title={'Advertencia'}
                tipo="descuento"
                content={<div style={{ textAlign: "left" }}>
                    <div>
                        <label>Actualmente cuentas con un descuento para el periodo:<br /></label>
                        <label style={{ margin: "1rem 0 1rem 4rem" }}>{nombresMeses[Number(formik.values.periodo) - 1]} del año {formik.values.anno}, con el número de referencia: {descuentoValor}.</label>
                    </div>
                    <div>

                        Queremos recordarte que, si decides continuar con nuestros servicios, perderás este beneficio. Sin embargo, si deseas conservar tu descuento, te invitamos amablemente a realizar el pago por medio de:
                        <ol style={{ margin: "1rem 0 1rem 2rem", listStyle: "unset" }}>
                            <li>Transferencia (SPEI)</li>
                            <li>Instituciones bancarias o Tiendas de conveniencia</li>
                        </ol>
                    </div>
                    ¡Estamos aquí para ayudarte en lo que necesites!

                </div>}

                icon={'error'}
                show={descuento}
                onHide={() => { showDescuento(false); }}
                buttonAction={() => { showDescuento(false); }}
                buttonActionCancel={() => { showDescuento(false); }}
            />
            <ModalActions
                title={'Advertencia'}
                content={'Alcanzaste el limite de declaraciones permitidas en el periodo seleccionado.'} icon={'error'}
                show={decLimiteAlcanzado}
                onHide={() => { setDecLimiteAlcanzado(false); }}
                buttonAction={() => { setDecLimiteAlcanzado(false); }}
            />
            <ModalActions
                title={'Advertencia'}
                content={'Solo se permite declarar de manera anual del 1 de enero al 17 de febrero del año en curso. Si no realizó la declaración dentro de la fecha, deberá de solicitar cambiar su régimen de pago en una oficina administrativa.'} icon={'error'}
                show={candadoFechaAnual}
                onHide={() => { setCandadoFechaAnual(false); }}
                buttonAction={() => { setCandadoFechaAnual(false); }}
            />
            <ModalActions
                title={'Declaración sin saldo a pagar'}
                content={'No hay importe a pagar en esta declaración, puedes dar clic en Regresar para realizar alguna modificación, o clic en Confirmar para enviar la declaración actual.'}
                icon={'error'}
                show={advertenciaPago}
                onHide={() => { setAdvertenciaPago(false); }}
                buttonAction={() => {
                    setTipoAccion(tipo.RECIBO)
                    calcularServicioSei(true);
                    setAdvertenciaPago(false);
                    setLoadingPago(true);
                }}
                buttonActionCancel={() => { setAdvertenciaPago(false) }}
                buttonText='Confirmar'
                cancelText='Regresar'
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingPago}

            >
                <h1>Esperando Respuesta </h1>
                <CircularProgress color="inherit" />
            </Backdrop>

        </>
    );
}