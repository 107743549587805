import { Button, Form, Col, Row as Fila, Modal } from "react-bootstrap";
import { View } from "@aws-amplify/ui-react";
import { useFormik } from "formik";
import { FaArrowLeft } from "react-icons/fa";
import Tooltip from '@mui/material/Tooltip';
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Swal from 'sweetalert2'
import { Backdrop, CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from "@mui/material";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoIosAddCircleOutline } from "react-icons/io";
import Iframe from "react-iframe";
import { ModalCSV } from "./ModalCSV";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { PiBroom } from "react-icons/pi";
import { SiMicrosoftexcel } from "react-icons/si";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { TfiReload } from "react-icons/tfi";
const ENDPOINT = process.env.REACT_APP_ALTAVEHICULAR  ; 
const ENDPOINT_DOCUMENTOS = process.env.REACT_APP_DOCUMENTOS  ;
const estadosArray = [
    { value: 'AGUASCALIENTES', label: 'Aguascalientes' },
    { value: 'BAJA CALIFORNIA', label: 'Baja California' },
    { value: 'BAJA CALIFORNIA SUR', label: 'Baja California Sur' },
    { value: 'CAMPECHE', label: 'Campeche' },
    { value: 'COAHUILA', label: 'Coahuila' },
    { value: 'COLIMA', label: 'Colima' },
    { value: 'CHIAPAS', label: 'Chiapas' },
    { value: 'CHIHUAHUA', label: 'Chihuahua' },
    { value: 'DISTRITO FEDERAL', label: 'Distrito Federal' },
    { value: 'GUANAJUATO', label: 'Guanajuato' },
    { value: 'GUERRERO', label: 'Guerrero' },
    { value: 'HIDALGO', label: 'Hidalgo' },
    { value: 'JALISCO', label: 'Jalisco' },
    { value: 'MEXICO', label: 'México' },
    { value: 'MICHOACAN', label: 'Michoacán' },
    { value: 'MORELOS', label: 'Morelos' },
    { value: 'NAYARIT', label: 'Nayarit' },
    { value: 'NUEVO LEON', label: 'Nuevo León' },
    { value: 'OAXACA', label: 'Oaxaca' },
    { value: 'PUEBLA', label: 'Puebla' },
    { value: 'QUERETARO', label: 'Querétaro' },
    { value: 'QUINTANA ROO', label: 'Quintana Roo' },
    { value: 'SAN LUIS POTOSI', label: 'San Luis Potosí' },
    { value: 'SINALOA', label: 'Sinaloa' },
    { value: 'SONORA', label: 'Sonora' },
    { value: 'TABASCO', label: 'Tabasco' },
    { value: 'TAMAULIPAS', label: 'Tamaulipas' },
    { value: 'TLAXCALA', label: 'Tlaxcala' },
    { value: 'VERACRUZ', label: 'Veracruz' },
    { value: 'YUCATAN', label: 'Yucatán' },
    { value: 'ZACATECAS', label: 'Zacatecas' },
].map(opcion => ({ ...opcion, label: opcion.label.toUpperCase() }));

function Row(props) {
    const { perDataTicket,data, setChangeInfo, changeInfo, handleCheckboxChange, selectedOptions, index, nuevoRegistroRef, rowsPerPage, token } = props;
    const [active, setActive] = useState(true);
    const [band, setBand] = useState("");
    const [loading, setLoading] = useState(false);
    const [viewDocuent, setViewDocument] = useState(false);
    const [infoDocument, setInfoDocument] = useState("");
    const initialValues = {};
    const validationSchema = {};

    validationSchema[`NoSerie-${data.formularioID}`] = yup
        .string()
        .required("Este campo es obligatorio").matches(/^[A-HJ-NPR-Z0-9]*$/, "No puede contener caracteres especiales ni O,Q,I,Ñ");
    validationSchema[`Confirmar-NoSerie-${data.formularioID}`] = yup
        .string()
        .required("Este campo es obligatorio")
        .oneOf(
            [yup.ref(`NoSerie-${data.formularioID}`), null],
            "Los Numero de serie no coinciden"
        );
    validationSchema[`Modelo-${data.formularioID}`] = yup
        .string()
        .required("Este campo es obligatorio").max(9999, "Valor no valido");
    validationSchema[`Factura-${data.formularioID}`] = yup.mixed().test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
            return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
    })
        .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
            if (!value) {
                return true; // Permitir si no hay archivo
            }

            const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
            return value.size <= maxSize;
        });
    validationSchema[`Repuve-${data.formularioID}`] = yup.mixed().test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
            return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
    })
        .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
            if (!value) {
                return true; // Permitir si no hay archivo
            }

            const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
            return value.size <= maxSize;
        });
    validationSchema[`HojaRecepcion-${data.formularioID}`] = yup.mixed().test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
            return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
    })
        .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
            if (!value) {
                return true; // Permitir si no hay archivo
            }

            const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
            return value.size <= maxSize;
        });
    validationSchema[`tituloAmericano-${data.formularioID}`] = yup.mixed().test('fileType', 'Solo se permiten archivos PDF', function (value) {
        if (!value) {
            return true; // Permitir si no hay archivo
        }

        return value.type === 'application/pdf';
    })
        .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
            if (!value) {
                return true; // Permitir si no hay archivo
            }

            const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
            return value.size <= maxSize;
        });
    validationSchema[`EstadoPro-${data.formularioID}`] = yup
        .string()
        .required("Este campo es obligatorio");
    validationSchema[`OrigenVehiculo-${data.formularioID}`] = yup
        .string();
    initialValues[`NoSerie-${data.formularioID}`] =
        data.numeroSerie === "0" ? "" : data.numeroSerie;
    initialValues[`Confirmar-NoSerie-${data.formularioID}`] =
        data.numeroSerie === "0" ? "" : data.numeroSerie;
    initialValues[`Modelo-${data.formularioID}`] =
        data.modelo === 0 ? "" : data.modelo;
    initialValues[`Factura-${data.formularioID}`] = (data.factura !== "" || data.factura !== undefined) ? data.factura : "";
    initialValues[`Repuve-${data.formularioID}`] = (data.factura !== "" || data.factura !== undefined) ? data.factura : "";
    initialValues[`HojaRecepcion-${data.formularioID}`] = (data.factura !== "" || data.factura !== undefined) ? data.factura : "";
    initialValues[`tituloAmericano-${data.formularioID}`] = (data.factura !== "" || data.factura !== undefined) ? data.factura : "";
    initialValues[`EstadoPro-${data.formularioID}`] =
        data.estadoProcedencia !== "" ? data.estadoProcedencia : "";
    initialValues[`OrigenVehiculo-${data.formularioID}`] =
        data.origenVehiculo !== "" ? data.origenVehiculo : "";


    const ValidarAuto = (data, data2) => {
        const idToast = toast.loading("Verificando Número de Serie...")

        if (data < 1900) {

            toast.update(idToast, { render: "Favor de verificar el modelo ingresado.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });
        } else {

            var myHeaders = new Headers();
            myHeaders.append("Authorization", token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                serie: data2,
                modelo: data,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            fetch(`${ENDPOINT}/check-vehicle`, requestOptions)
                .then((response) => response.json())
                .then((result) => {

                    setLoading(false);

                    if (result.exists === true) {
                        formik.values[`Modelo-${data.formularioID}`] = "";
                        formik.errors[`Modelo-${data.formularioID}`] = true;
                        formik.touched[`Modelo-${data.formularioID}`] = true;
                        setActive(result.exists);
                        toast.update(idToast, { render: result.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });

                        /*  Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: result.message,
                        }); */
                    } else if (result.error !== undefined) {
                        toast.update(idToast, { render: result.error, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });

                    } else {
                        toast.dismiss(idToast.current);
                        setActive(result.exists);
                        /*  Swal.fire({
                            icon: "success",
                            title: "Exito!",
                            text: result.message,
                        }); */
                    }

                })
                .catch((error) => {
                    console.log("error", error);
                    /*      setLoading(false); */
                });
        }
    };

    const UpdateVehiculos = (values) => {

        const idToast = toast.loading("Verificando...")
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify([
            {
                serie: values.serie,
                modelo: values.modelo,
                usuarioID: data.usuarioID,
                estadoProcedencia: values.estadoProcedencia,
                origenVehiculo: values.origenVehiculo,
                factura: "",
                flotillaID: data.flotillaID,
                dynamoID: data.id,
            },
        ]);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/vehiculo`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                setChangeInfo(!changeInfo)
                toast.update(idToast, { render: result.message, type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, });
                setActive(true);
            })
            .catch((error) => {
                console.log("error", error);
            });

    };


    const subirFactura = async (values) => {

        const idToast = toast.loading("Subiendo Factura")
        const arrayArchivos = [];
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                if (key === `Repuve-${data.formularioID}` || key === `Factura-${data.formularioID}` || key === `HojaRecepcion-${data.formularioID}` || key === `tituloAmericano-${data.formularioID}`) {
                    if (values[key]?.name !== undefined) {

                        let name = "";

                        if (key === `Repuve-${data.formularioID}`) {
                            name = "Repuve"
                        } else if (key === `Factura-${data.formularioID}`) {
                            name = "Factura"
                        } else if (key === `HojaRecepcion-${data.formularioID}`) {
                            name = "HojaRecepcion"
                        } else if (key === `tituloAmericano-${data.formularioID}`) {
                            name = "tituloAmericano"
                        }
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/pdf");

                        var file = values[key];

                        var requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: file,
                            redirect: "follow",
                        };

                        try {
                            const response = await fetch(`${ENDPOINT_DOCUMENTOS}/subir/?documentName=${name}`, requestOptions);
                            const result = await response.json();

                            if (result.s3_url !== undefined) {
                                const nuevoValor = { name: name, url: result.s3_url };
                                arrayArchivos.push(nuevoValor);
                            } else {
                                console.log("-----", result)
                                toast.update(idToast, { render: "No se pudo subir el archivo", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });
                            }
                        } catch (error) {
                            console.log("error", error);
                        }
                    }
                }
            }
        }

        if (arrayArchivos.length !== 0) {
            ActualizaFacturadynamo(arrayArchivos, idToast);
        }
        setBand("");
    };


    const ActualizaFacturadynamo = (values, idToast) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            paths: values,
            dynamoID: data.id,
            ticketType: 17,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/attachments`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

                setActive(true);
                setChangeInfo(!changeInfo);
                toast.update(idToast, { render: `Se ha creado correctamente el vehículo #${data.formularioID}`, type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, });
                formik.resetForm();
            })
            .catch((error) => console.log("error", error));
    };
    function buscarURLPorNombre(objeto, nombreBuscado) {
        for (const key in objeto) {
            if (objeto[key].name === nombreBuscado) {
                return objeto[key].url;
            }
        }
        return null; // Retorna null si no se encuentra ninguna coincidencia
    }
    const handleDocumento = (url, name) => {
        const urlDocument = JSON.parse(url);
        const data = buscarURLPorNombre(urlDocument, name);

        setInfoDocument(data);
        setViewDocument(true);
    };
    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: yup.object().shape(validationSchema),
        onSubmit: (values, { setSubmitting }) => {
            console.log(values);
            setTimeout(() => {
                if (band === "Factura" || band === "Titulo") {
                    if (formik.values[`OrigenVehiculo-${data.formularioID}`] === "NACIONAL") {
                        subirFactura(values);
                    } else {
                        if (formik.values[`Repuve-${data.formularioID}`] !== "" && formik.values[`HojaRecepcion-${data.formularioID}`] !== "" && formik.values[`tituloAmericano-${data.formularioID}`] !== "") {
                            subirFactura(values);

                        } else {
                            toast.error('Documentos Faltantes', {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });
                        }
                    }
                } else {

                    const idToast = toast.loading("Verificando Número de Serie...")
                    const dataValues = {
                        serie: values[`NoSerie-${data.formularioID}`],
                        modelo: parseInt(values[`Modelo-${data.formularioID}`]),
                        estadoProcedencia: values[`EstadoPro-${data.formularioID}`],
                        origenVehiculo: values[`OrigenVehiculo-${data.formularioID}`],
                    }
                    var myHeaders = new Headers();
                    myHeaders.append("Authorization", token);
                    myHeaders.append("Content-Type", "application/json");

                    var raw = JSON.stringify({
                        serie: dataValues.serie,
                        modelo: dataValues.modelo.toString(),
                    });

                    var requestOptions = {
                        method: "POST",
                        headers: myHeaders,
                        body: raw,
                        redirect: "follow",
                    };

                    fetch(`${ENDPOINT}/check-vehicle`, requestOptions)
                        .then((response) => response.json())
                        .then((result) => {


                            if (result.exists === true) {

                                toast.update(idToast, { render: result.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });

                                /*  Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: result.message,
                                }); */
                            } else if (result.error !== undefined) {
                                toast.update(idToast, { render: result.error, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });

                            } else {
                                toast.dismiss(idToast.current);
                                UpdateVehiculos(dataValues);
                                /*  Swal.fire({
                                    icon: "success",
                                    title: "Exito!",
                                    text: result.message,
                                }); */
                            }

                        })
                        .catch((error) => {
                            console.log("error", error);
                            /*      setLoading(false); */
                        });
                }
                setSubmitting(false);
            }, 400);
        },
    });

    const handleBlur = field => {
        formik.setFieldTouched(field, true);
    };
    /* 
        const handleLimpiar = (values) => {
    
            const idToast = toast.loading("Eliminando el Vehículo...")
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
    
            var raw = JSON.stringify([
                {
                    serie: "",
                    modelo: 0,
                    usuarioID: values.usuarioID,
                    factura: "",
                    estadoProcedencia: "",
                    origenVehiculo: "",
                    flotillaID: values.flotillaID,
                    dynamoID: values.id,
    
                },
            ]);
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };
    
            fetch(`${ENDPOINT}/vehiculo`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
    
                    setLoading(false);
                    formik.resetForm();
                    setActive(true);
                    setChangeInfo(!changeInfo)
                    formik.values[`NoSerie-${data.formularioID}`] = "";
                    formik.values[`Modelo-${data.formularioID}`] = "";
                    formik.values[`Factura-${data.formularioID}`] = null;
                    formik.values[`EstadoPro-${data.formularioID}`] = "";
                    formik.values[`Confirmar-NoSerie-${data.formularioID}`] = "";
                    formik.values[`OrigenVehiculo-${data.formularioID}`] = "";
                    toast.update(idToast, { render: `Se ha eliminado correctamente el vehículo`, type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, });
    
                })
                .catch((error) => {
                    console.log("error", error);
                });
        } */

    const handleChange = event => {
        const { name, value } = event.target;
        const uppercaseValue = value.toUpperCase();

        formik.handleChange({
            target: {
                name,
                value: uppercaseValue,
            },
        });
    };

    const handleInputChangeCodigo = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, "");
        if (numericValue.length <= 4) {
            formik.setFieldValue(name, numericValue);
            if (numericValue.length === 4) {

                if (!formik.errors[`Confirmar-NoSerie-${data.formularioID}`]) {
                    ValidarAuto(
                        numericValue,
                        formik.values[`NoSerie-${data.formularioID}`]
                    );
                } else {
                    formik.values[`EstadoPro-${data.formularioID}`] = "";
                }
            }
        }
    };

    const disbledFormEstado = (ac, isComplete) => {

        if (ac === true && isComplete === 1) {
            return true
        } else if (ac === true && isComplete === 0) {
            return true;
        } else if (ac === false && isComplete === 1) {
            return true;
        }
    }

    const disbledInputFile = (activer, isComplete) => {

        if (activer === "" && isComplete === 0) {
            return true
        } else if (activer === undefined && isComplete === 0) {
            return true
        } else {
            return false;
        }
    }

    const disabledInput = (activer, data) => {

        if (activer === 1 && data !== "0") {
            return false
        } else if (activer === 0 && data === "0") {
            return true
        } else if (activer === 0 && data === undefined) {
            return true
        }
    }
    const disabledInputModel = (activer, data) => {

        if (activer === 1 && data !== 0) {
            return false
        } else if (activer === 0 && data === 0) {
            return true
        } else if (activer === 0 && data === undefined) {
            return true
        }
    }

    const fieldName = `EstadoPro-${data.formularioID}`;
    const handleSelectChange = (selectedOption) => {
        const name = fieldName;
        const value = selectedOption ? selectedOption.value : null;

        if (value === undefined) {
            formik.setFieldValue(name, null);
        } else {
            // Actualizar el valor del campo en Formik
            formik.setFieldValue(name, value);
        }
    };
    const handleSelect = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        formik.setFieldValue(name, value);
        formik.setSubmitting()
        if (value !== "") {
            formik.handleSubmit()
        }
    }
    return (
        <>

            <TableRow onSubmit={formik.handleSubmit} ref={index === rowsPerPage - 1 ? nuevoRegistroRef : null} noValidate>
                <TableCell style={{ position: "sticky", left: "0", backgroundColor: "white", zIndex: "1" }}>
                    <Form.Group as={Col} >
                        {/* <Form.Label>{data.formularioID}</Form.Label> */}
                        <Form.Check
                            type="checkbox"
                            name={data.id}
                            label={data.formularioID}
                            value={data.id}
                            disabled={perDataTicket?.status?.S=== "Plantilla" ? true: false}
                            onChange={handleCheckboxChange}
                            checked={selectedOptions.includes(data.id)}
                        />
                    </Form.Group>
                </TableCell>
                <TableCell>
                    <Form.Group
                        as={Col}
                        controlId={`Formik-NoSerie-${data.formularioID}`}
                        className="position-relative"
                    >
                        {data.numeroSerie === undefined || data.numeroSerie === "0" ?

                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name={`NoSerie-${data.formularioID}`}
                                disabled={!disabledInput(data.isComplete, data.numeroSerie)}
                                maxLength="17"
                                placeholder="No. Serie"
                                readOnly
                                plaintext
                                value={formik.values[`NoSerie-${data.formularioID}`] ?? ""}
                                onChange={handleChange}
                                onBlur={() => handleBlur(`Formik-NoSerie-${data.formularioID}`)}
                                isValid={
                                    formik.touched[`NoSerie-${data.formularioID}`] &&
                                    !formik.errors[`NoSerie-${data.formularioID}`]
                                }
                                isInvalid={

                                    !!formik.errors[`NoSerie-${data.formularioID}`]
                                }
                            />
                            :
                            <Form.Label style={{ display: "flex", justifyContent: "center" }}>{formik.values[`NoSerie-${data.formularioID}`] ?? ""}</Form.Label>

                        }
{/*                         <Form.Control.Feedback type="invalid" tooltip>{formik.errors[`NoSerie-${data.formularioID}`]}</Form.Control.Feedback>
 */}                    </Form.Group>

                </TableCell>
                <TableCell>
                    <Form.Group
                        as={Col}
                        controlId={`Formik-Confirmar-NoSerie-${data.formularioID}`}
                        className="position-relative"
                    >
                        {data.numeroSerie === undefined || data.numeroSerie === "0" ?

                            <Form.Control
                                type="text"
                                autoComplete="off"
                                name={`Confirmar-NoSerie-${data.formularioID}`}
                                placeholder="No. Serie"
                                maxLength="17"
                                readOnly
                                plaintext
                                disabled={!disabledInput(data.isComplete, data.numeroSerie)}
                                onBlur={() => handleBlur(`Confirmar-NoSerie-${data.formularioID}`)}
                                value={
                                    formik.values[`Confirmar-NoSerie-${data.formularioID}`] ?? ""
                                }
                                onChange={handleChange}
                                isValid={
                                    formik.touched[`Confirmar-NoSerie-${data.formularioID}`] &&
                                    !formik.errors[`Confirmar-NoSerie-${data.formularioID}`]
                                }
                                isInvalid={
                                    formik.touched[`Confirmar-NoSerie-${data.formularioID}`] &&
                                    !!formik.errors[`Confirmar-NoSerie-${data.formularioID}`]
                                }
                            />
                            :
                            <Form.Label style={{ display: "flex", justifyContent: "center" }}>{formik.values[`NoSerie-${data.formularioID}`] ?? ""}</Form.Label>

                        }
                      {/*   <Form.Control.Feedback type="invalid" tooltip>
                            {formik.errors[`Confirmar-NoSerie-${data.formularioID}`]}
                        </Form.Control.Feedback> */}
                    </Form.Group>
                </TableCell>
                <TableCell>
                    <Form.Group as={Col} controlId={`Formik-Modelo-${data.formularioID}`}>
                        {data.modelo === undefined || data.modelo === 0 ?
                            <Form.Control
                                disabled={!disabledInputModel(data.isComplete, data.modelo)}
                                type="text"
                                name={`Modelo-${data.formularioID}`}
                                placeholder="Modelo"
                                readOnly
                                plaintext
                                autoComplete="off"
                                /*   onBlur={() => {
                                      //formik.values[`EstadoPro-${data.formularioID}`]
                                      if (!formik.errors[`Confirmar-NoSerie-${data.formularioID}`]) {
                                          ValidarAuto(
                                              formik.values[`Modelo-${data.formularioID}`],
                                              formik.values[`NoSerie-${data.formularioID}`]
                                          );
                                      } else {
                                          formik.values[`EstadoPro-${data.formularioID}`] = "";
                                      }
                                  }} */
                                value={formik.values[`Modelo-${data.formularioID}`] ?? ""}
                                onChange={handleInputChangeCodigo}
                                isValid={
                                    formik.touched[`Modelo-${data.formularioID}`] &&
                                    !formik.errors[`Modelo-${data.formularioID}`]
                                }
                                isInvalid={
                                    formik.touched[`Modelo-${data.formularioID}`] &&
                                    !!formik.errors[`Modelo-${data.formularioID}`]
                                }
                            />
                            :
                            <Form.Label style={{ display: "flex", justifyContent: "center" }}>{formik.values[`Modelo-${data.formularioID}`] ?? ""}</Form.Label>

                        }
                    </Form.Group>
                </TableCell>
                <TableCell>
                    <Form.Group
                        as={Col}
                        controlId={`Formik-EstadoPro-${data.formularioID}`}
                    >
                        {data.estadoProcedencia === undefined || data.estadoProcedencia === "" ?

                            <Select
                                classNamePrefix="custom-select"
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                }}
                                name={fieldName}
                                setValue={formik.values[fieldName] === undefined ? estadosArray[0] : formik.values[fieldName]}
                                isDisabled={disbledFormEstado(active, data.isComplete)}
                                onChange={handleSelectChange}
                                autoComplete="off"
                                /* onBlur={() => formik.handleSubmit()} */
                                /* onFocus={() => formik.handleSubmit()} */
                                isValid={formik.touched[fieldName] && !formik.errors[fieldName]}
                                isInvalid={formik.touched[fieldName] && !!formik.errors[fieldName]}
                                options={estadosArray}
                                isSearchable={true}

                                placeholder="Estado de Procedencia"
                            />
                            :

                            <Form.Label style={{ display: "flex", justifyContent: "center" }}>{data.estadoProcedencia}</Form.Label>
                        }

                    </Form.Group>

                </TableCell>
                <TableCell>
                    <Form.Group
                        as={Col}
                        controlId={`Formik-OrigenVehiculo-${data.formularioID}`}
                    >
                        {data.origenVehiculo === undefined || data.origenVehiculo === "" ?

                            /*   <Select
                                  name={fieldName}
                                  setValue={formik.values[fieldName] === undefined ? estadosArray[0] : formik.values[fieldName]}
                                  isDisabled={disbledFormEstado(active, data.isComplete)}
                                  onChange={handleSelectChange}
                                  autoComplete="off"
                                  onBlur={() => formik.handleSubmit()}
                                  /* onFocus={() => formik.handleSubmit()} 
                                  isValid={formik.touched[fieldName] && !formik.errors[fieldName]}
                                  isInvalid={formik.touched[fieldName] && !!formik.errors[fieldName]}
                                  options={origenArray}
                                  isSearchable={true}
  
                                  placeholder="Estado de Origen"
                              />
                          } */
                            <Form.Select
                                type="text"
                                placeholder="Estado de Procedencia"
                                name={`OrigenVehiculo-${data.formularioID}`}
                                readOnly
                                plaintext
                                value={formik.values[`OrigenVehiculo-${data.formularioID}`] ?? ""}

                                disabled={disbledFormEstado(active, data.isComplete)}

                                onChange={(e) => {
                                    handleSelect(e)
                                    formik.validateForm().then(() => {
                                        formik.validateForm()
                                    })
                                }}
                                autoComplete="off"
                                isValid={
                                    formik.touched[`OrigenVehiculo-${data.formularioID}`] &&
                                    !formik.errors[`OrigenVehiculo-${data.formularioID}`]
                                }
                                isInvalid={
                                    formik.touched[`OrigenVehiculo-${data.formularioID}`] &&
                                    !!formik.errors[`OrigenVehiculo-${data.formularioID}`]
                                }
                            >
                                <option value="">Seleciona</option>
                                <option value="NACIONAL">NACIONAL</option>
                                <option value="EXTRANJERO">EXTRANJERO</option>

                            </Form.Select>

                            :

                            <Form.Label style={{ display: "flex", justifyContent: "center" }}>{data.origenVehiculo}</Form.Label>
                        }
                    </Form.Group>
                </TableCell>
                {formik.values[`OrigenVehiculo-${data.formularioID}`] === "EXTRANJERO" ?
                    <>
                        <TableCell>
                            <Form.Group
                                as={Col}
                                controlId={`Formik-Repuve-${data.formularioID}`}
                                className="position-relative"
                            >

                                {data.isComplete === 0 ? (
                                    <>
                                        <Form.Control
                                            type="file"
                                            placeholder="Decreto de regularización o repuve"
                                            accept=".pdf"
                                            name={`Repuve-${data.formularioID}`}
                                            onBlur={() => {

                                                if (formik.isValid) {
                                                    const archivoValue = formik.values[`Repuve-${data.formularioID}`];
                                                    if (archivoValue && archivoValue !== "") {
                                                        /* const datosAr = [...archivos, archivoValue]
                                                        setArchivos(datosAr) 
                                                        subirFactura(archivoValue,"Repube"); 
                                                        */
                                                    }
                                                }
                                            }
                                            }
                                            autoComplete="off"
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    `Repuve-${data.formularioID}`,
                                                    event.currentTarget.files[0]
                                                );
                                            }}

                                            isInvalid={

                                                !!formik.errors[`Repuve-${data.formularioID}`]
                                            }
                                            disabled={disbledInputFile(data.estadoProcedencia, data.isComplete)}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>{formik.errors[`Repuve-${data.formularioID}`]}</Form.Control.Feedback>
                                    </>
                                ) :
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        onClick={() => handleDocumento(data.factura, "Repuve")}
                                        defaultValue={"Ver Documento"}
                                    />
                                }
                            </Form.Group>
                        </TableCell>
                        <TableCell>
                            <Form.Group
                                as={Col}
                                controlId={`Formik-HojaRecepcion-${data.formularioID}`}
                                className="position-relative"
                            >

                                {data.isComplete === 0 ? (
                                    <>
                                        <Form.Control
                                            type="file"
                                            placeholder="HojaRecepcion"
                                            accept=".pdf"
                                            name={`HojaRecepcion-${data.formularioID}`}
                                            onBlur={() => {

                                                if (formik.isValid) {
                                                    const archivoValue = formik.values[`HojaRecepcion-${data.formularioID}`];

                                                    if (archivoValue && archivoValue !== "") {
                                                        /*  subirFactura(archivoValue,"HojaRecepcion");  */
                                                    }
                                                }
                                            }
                                            }
                                            autoComplete="off"
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    `HojaRecepcion-${data.formularioID}`,
                                                    event.currentTarget.files[0]
                                                );
                                            }}

                                            isInvalid={

                                                !!formik.errors[`HojaRecepcion-${data.formularioID}`]
                                            }
                                            disabled={formik.values[`Repuve-${data.formularioID}`] !== "" ? false : true || disbledInputFile(data.estadoProcedencia, data.isComplete)}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>{formik.errors[`HojaRecepcion-${data.formularioID}`]}</Form.Control.Feedback>
                                    </>
                                ) :
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        onClick={() => handleDocumento(data.factura, "HojaRecepcion")}
                                        defaultValue={"Ver Documento"}
                                    />
                                }
                            </Form.Group>
                        </TableCell>

                        <TableCell>
                            <Form.Group
                                as={Col}
                                controlId={`Formik-tituloAmericano-${data.formularioID}`}
                                className="position-relative"
                            >

                                {data.isComplete === 0 ? (
                                    <>
                                        <Form.Control
                                            type="file"
                                            placeholder="Factura"
                                            accept=".pdf"
                                            name={`tituloAmericano-${data.formularioID}`}

                                            autoComplete="off"
                                            onChange={(event) => {
                                                formik.setFieldValue(
                                                    `tituloAmericano-${data.formularioID}`,
                                                    event.currentTarget.files[0]
                                                );
                                                formik.validateForm().then(() => {
                                                    setBand("Titulo")
                                                    formik.handleSubmit();
                                                })
                                            }}

                                            isInvalid={

                                                !!formik.errors[`tituloAmericano-${data.formularioID}`]
                                            }
                                            disabled={formik.values[`HojaRecepcion-${data.formularioID}`] !== "" ? false : true || disbledInputFile(data.estadoProcedencia, data.isComplete)}
                                        />
                                        <Form.Control.Feedback type="invalid" tooltip>{formik.errors[`tituloAmericano-${data.formularioID}`]}</Form.Control.Feedback>
                                    </>
                                ) :
                                    <Form.Control
                                        readOnly
                                        plaintext
                                        onClick={() => handleDocumento(data.factura, "tituloAmericano")}
                                        defaultValue={"Ver Documento"}
                                    />
                                }
                            </Form.Group>
                        </TableCell>
                    </>
                    :
                    <>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </>
                }
                {formik.values[`OrigenVehiculo-${data.formularioID}`] === "NACIONAL" ?
                    <TableCell>
                        <Form.Group
                            as={Col}
                            controlId={`Formik-Factura-${data.formularioID}`}
                            className="position-relative"
                        >

                            {data.isComplete === 0 ? (
                                <>
                                    <Form.Control
                                        type="file"
                                        placeholder="Factura"
                                        accept=".pdf"
                                        name={`Factura-${data.formularioID}`}
                                        /*     onBlur={() => {
   
                                               if (formik.isValid) {
                                                   const archivoValue = formik.values[`Factura-${data.formularioID}`];
   
                                                   if (archivoValue && archivoValue !== "") {
                                                       if (formik.values[`OrigenVehiculo-${data.formularioID}`] === "NACIONAL") {
                                                           subirFactura(formik.values);
                                                       } else {
                                                           if (formik.values[`Repuve-${data.formularioID}`] !== "" && formik.values[`HojaRecepcion-${data.formularioID}`] !== "" && formik.values[`tituloAmericano-${data.formularioID}`] !== "") {
                                                               subirFactura(formik.values);
   
                                                           } else {
                                                               toast.error('Documentos Faltantes', {
                                                                   position: "top-right",
                                                                   autoClose: 5000,
                                                                   hideProgressBar: false,
                                                                   closeOnClick: true,
                                                                   pauseOnHover: true,
                                                                   draggable: true,
                                                                   progress: undefined,
                                                                   theme: "light",
                                                               });
                                                           }
                                                       }
                                                   }
                                               }
                                           }
                                           }  */
                                        autoComplete="off"
                                        onChange={(event) => {

                                            formik.setFieldValue(
                                                `Factura-${data.formularioID}`,
                                                event.currentTarget.files[0]
                                            );
                                            formik.validateForm().then(() => {
                                                setBand("Factura")
                                                formik.submitForm();

                                            })

                                        }}

                                        isInvalid={

                                            !!formik.errors[`Factura-${data.formularioID}`]
                                        }
                                        disabled={disbledInputFile(data.estadoProcedencia, data.isComplete)}
                                    />
                                    <Form.Control.Feedback type="invalid" tooltip>{formik.errors[`Factura-${data.formularioID}`]}</Form.Control.Feedback>
                                </>
                            ) :
                                <Form.Control
                                    readOnly
                                    plaintext
                                    onClick={() => handleDocumento(data.factura, "Factura")}
                                    defaultValue={"Ver Documento"}
                                />
                            }
                        </Form.Group>
                    </TableCell>
                    :
                    <TableCell></TableCell>
                }
                <TableCell style={{ display: "flex", minHeight: "89px" }}>
                    {/*  <>
                        <IconButton onClick={() => handleLimpiar(data)}  >
                            <PiBroom />
                        </IconButton>

                    </> */}
                </TableCell>
            </TableRow>
            <Modal
                show={viewDocuent}
                animation={false}
                onHide={() => setViewDocument(false)}
                centered
                size="lg"
            >
                <Modal.Header>
                    <Modal.Title>Documentos </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Iframe
                        src={infoDocument}
                        height="700px"
                        width="750px"
                        display="initial"
                        position="relative"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setViewDocument(false)}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export const FormCapturaVehiculos = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [show, setShow] = useState(false);
    const location = useLocation();
    const datos = location.state;
    const [rows, setRows] = useState([]);
    const [helpView, setHelpView] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [changeInfo, setChangeInfo] = useState(true);
    const [perData, setPerData] = useState(null);
    const [perDataTicket, setPerDataTicket] = useState(null);

    const [selectAll, setSelectAll] = useState(false);

    const [selectedOptions, setSelectedOptions] = useState([]);

    // Maneja el cambio del checkbox principal
    const handleSelectAllChange = (event) => {
        const checked = event.target.checked;
        setSelectAll(checked);
        setSelectedOptions(checked ? rows.map(option => option.id) : []);
    };

    // Maneja el cambio de los checkboxes individuales
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedOptions((prevSelectedOptions) => {
            if (checked && !prevSelectedOptions.includes(value)) {
                // Agregar el valor si el checkbox está marcado y no está ya en el array
                return [...prevSelectedOptions, value];
            } else if (!checked) {
                // Remover el valor si el checkbox está desmarcado
                return prevSelectedOptions.filter((option) => option !== value);
            } else {
                // Devolver el estado sin cambios si el checkbox ya está marcado
                return prevSelectedOptions;
            }
        });

        // Actualizar el estado del checkbox principal
        if (checked) {
            setSelectAll(selectedOptions.length + 1 === rows.length);
        } else {
            setSelectAll(false);
        }
    };
    const [token, setToken] = useState("");

    useEffect(() => {
        const fetchData = async () => {
            const url = `${ENDPOINT}/auth`;

            try {
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },

                });

                if (!response.ok) {
                    throw new Error(`Error en la solicitud: ${response.status}`);
                }

                const data = await response.json();
                setToken(data.token);
                // Puedes manejar la respuesta según tus necesidades
            } catch (error) {
                console.error("Error:token", error.message);
                // Puedes manejar el error según tus necesidades
            }
        };

        // Llama a la función para realizar la solicitud
        fetchData();
    }, []);
    const validationSchema = yup.object().shape({
        NoSerie: yup.string().required("Este campo es obligatorio").matches(/^[A-HJ-NPR-Z0-9]*$/, "No puede contener caracteres especiales ni O,Q,I,Ñ"),
        ConfirmarNoSerie: yup.string().required("Los Numero de serie no coinciden").oneOf([yup.ref(`NoSerie`), null], "Los Numero de serie no coinciden"),
        Modelo: yup.string().required("Este campo es obligatorio").max(9999, "Valor no valido"),
        Factura: yup.mixed().test('fileType', 'Solo se permiten archivos PDF', function (value) {
            if (!value) {
                return true; // Permitir si no hay archivo
            }
            return value.type === 'application/pdf';
        })
            .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
                if (!value) {
                    return true; // Permitir si no hay archivo
                }
                const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
                return value.size <= maxSize;
            }),
        Repuve: yup.mixed().test('fileType', 'Solo se permiten archivos PDF', function (value) {
            if (!value) {
                return true; // Permitir si no hay archivo
            }
            return value.type === 'application/pdf';
        })
            .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
                if (!value) {
                    return true; // Permitir si no hay archivo
                }
                const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
                return value.size <= maxSize;
            }),
        HojaRecepcion: yup.mixed().test('fileType', 'Solo se permiten archivos PDF', function (value) {
            if (!value) {
                return true; // Permitir si no hay archivo
            }
            return value.type === 'application/pdf';
        })
            .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
                if (!value) {
                    return true; // Permitir si no hay archivo
                }
                const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
                return value.size <= maxSize;
            }),
        tituloAmericano: yup.mixed().test('fileType', 'Solo se permiten archivos PDF', function (value) {
            if (!value) {
                return true; // Permitir si no hay archivo
            }
            return value.type === 'application/pdf';
        })
            .test('fileSize', 'El archivo no debe ser mayor a 4 MB', function (value) {
                if (!value) {
                    return true; // Permitir si no hay archivo
                }
                const maxSize = 4 * 1024 * 1024; // 4 MB en bytes
                return value.size <= maxSize;
            }),
        EstadoPro: yup.string().required("Este campo es obligatorio"),
        OrigenVehiculo: yup.string().required("Este campo es obligatorio"),

    });



    const formikSelect = useFormik({
        validationSchema,
        enableReinitialize: true,
        onSubmit: (values, { setSubmitting }) => {
            if (values.OrigenVehiculo === "NACIONAL" && values.Factura === "") {
                toast.warn('Documentos Faltantes', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else if (values.OrigenVehiculo === "EXTRANJERO" && (values.Repuve === "" || values.HojaRecepcion === "" || values.tituloAmericano === "")) {
                toast.warn('Documentos Faltantes', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                ValidarAuto(values.Modelo, values.NoSerie)
                    .then((result) => {

                        if (result.exists === false) {


                            AgregarVehiculos().then((res) => {
                                if (res.vehiculoID !== undefined) {
                                    subirFactura(values, res.vehiculoID);
                                }
                            })

                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                        // Manejar el error aquí
                    });
            }
        },
        initialValues: {
            NoSerie: "",
            ConfirmarNoSerie: "",
            Modelo: "",
            Factura: "",
            Repuve: "",
            HojaRecepcion: "",
            tituloAmericano: "",
            EstadoPro: "",
            OrigenVehiculo: "",

        }
    });
    const subirFactura = async (values, vehiculoID) => {
        const idToast = toast.loading("Agregando Vehículo");
        const arrayArchivos = [];
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                if (key === 'Repuve' || key === 'Factura' || key === 'HojaRecepcion' || key === 'tituloAmericano') {
                    // Verificar si el campo no está vacío y tiene un valor definido
                    if (values[key] && values[key].value !== "") {

                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "application/pdf");

                        var file = values[key];

                        var requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: file,
                            redirect: "follow",
                        };

                        try {
                            const response = await fetch(`${ENDPOINT_DOCUMENTOS}/subir/?documentName=${key}`, requestOptions);
                            const result = await response.json();


                            if (result.s3_url !== undefined) {
                                const nuevoValor = { name: key, url: result.s3_url };
                                arrayArchivos.push(nuevoValor);
                            } else {
                                toast.update(idToast, { render: "No se pudo subir el archivo", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true });
                            }
                        } catch (error) {
                            console.log("error", error);
                            toast.update(idToast, { render: "Error al subir el archivo", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true });
                        }
                    }
                }
            }
        }

        if (arrayArchivos.length !== 0) {
            UpdateVehiculos(arrayArchivos, idToast, values, vehiculoID);
        } else {
            toast.update(idToast, { render: "No se subieron archivos, todos los campos estaban vacíos", type: "warning", isLoading: false, autoClose: 3000, closeOnClick: true });
        }
    };


    const UpdateVehiculos = (archivos, idToast, values, vehiculoID) => {

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var raw = JSON.stringify([
            {
                serie: values.NoSerie,
                modelo: parseInt(values.Modelo),
                usuarioID: perDataTicket.usuarioID.S,
                estadoProcedencia: values.EstadoPro,
                origenVehiculo: values.OrigenVehiculo,
                paths: archivos,
                flotillaID: perDataTicket.id.S,
                dynamoID: vehiculoID,
            },
        ]);
        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/vehiculo`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                toast.update(idToast, { render: "Vehículo agregado correctamente", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true });
                setChangeInfo(!changeInfo)
                formikSelect.resetForm()
                const nuevaPagina = Math.ceil((rows.length + 1) / rowsPerPage) - 1;
                setPage(nuevaPagina);
                nuevoRegistroRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
            })
            .catch((error) => {
                console.log("error", error);
            });

    };
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        let usuario = "";
        let id = "";
        if (datos.ticket === undefined) {
            usuario = datos.usuarioID.S;
            id = datos.id.S;
        } else {
            usuario = datos.ticket.usuarioID;
            id = datos.ticket.id;
        }
        var raw = JSON.stringify({
            usuarioID: usuario,
            flotillaID: id,
        });

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
        };

        fetch(`${ENDPOINT}/listaByFlotilla`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                result.sort(function (a, b) {
                    return a.formularioID - b.formularioID;
                });

                setRows(result);
            })
            .catch((error) => console.log("error", error));
    }, [changeInfo]);

    useEffect(() => {
        const fetchData = async () => {
            const requestData = {
                id: location.state?.ticket?.personalidadID ? location.state?.ticket?.personalidadID : location.state?.personalidadID.S,
                ticketType: 15,
            };
            try {
                const response = await fetch(`${ENDPOINT}/getTicket`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestData),
                });

                if (!response.ok) {
                    throw new Error(`Error en la solicitud: ${response.status}`);
                }

                const data = await response.json();
                setPerData(data);

            } catch (error) {
                console.error("Error al obtener el ticket:", error);
                // Puedes manejar el error según tus necesidades
            }
        };

        fetchData();
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const requestData = {
                id: location.state?.ticket?.id ? location.state?.ticket?.id : location.state?.id.S,
                ticketType: 16,
            };
            try {
                const response = await fetch(`${ENDPOINT}/getTicket`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestData),
                });

                if (!response.ok) {
                    throw new Error(`Error en la solicitud: ${response.status}`);
                }

                const data = await response.json();
                setPerDataTicket(data.Item);

            } catch (error) {
                console.error("Error al obtener el ticket:", error);
                // Puedes manejar el error según tus necesidades
            }
        };

        fetchData();
    }, [changeInfo]);
    const nuevoRegistroRef = useRef(null);

    const AgregarVehiculos = () => {

        return new Promise((resolve, reject) => {
            let id = "";
            if (datos.ticket === undefined) {
                id = datos.id.S;
            } else {
                id = datos.ticket.id;
            }

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                flotillaID: id,
                agregar: true,
            });

            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            fetch(`${ENDPOINT}/flotilla/changeSize`, requestOptions)
                .then((response) => response.json())
                .then((result) => {

                    resolve(result); // Resuelve la promesa con el resultado

                })
                .catch((error) => {
                    console.log("error", error);
                    reject(error); // Rechaza la promesa con el error
                });
        });
    };


    const EliminarVehiculos = () => {
        if (selectedOptions.length === 0) {
            toast.warn('Sin Vehículos seleccionados', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            const idToast = toast.loading("Eliminar vehiculos seleccionados")


            let id = "";
            if (datos.ticket === undefined) {

                id = datos.id.S;
            } else {

                id = datos.ticket.id;
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify({


                flotillaID: id,
                eliminar: selectedOptions,

            });
            var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            };

            fetch(`${ENDPOINT}/flotilla/changeSize`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    if (result.error !== undefined) {
                        toast.update(idToast, { render: result.error, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });
                    } else {
                        toast.update(idToast, { render: "Registro eliminado correctamente", type: "success", isLoading: false, autoClose: 3000, closeOnClick: true, });

                        setChangeInfo(!changeInfo)
                        setSelectAll(false);
                        setSelectedOptions([])
                        /*  if (rows.length <= page * rowsPerPage) {
                             setPage(page > 0 ? page - 1 : 0);
                             nuevoRegistroRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
                         } */

                    }
                })
                .catch((error) => {
                    console.log("error", error);
                });

        }
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleTerminarFlotilla = () => {

        let usuario = "";
        let id = "";
        if (datos.ticket === undefined) {

            id = datos.id.S;
        } else {

            id = datos.ticket.id;
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "dynamoID": id,
            "ticketType": 16
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        let timerInterval;
        Swal.fire({
            title: "Creación de Flotilla",
            html: "Será redirigido a la pantalla de inicio del trámite Emplacamiento Exprés y recibirá una notificación cuando comience el proceso de acreditación vehicular.",
            timer: 5000,
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading();
            },
            willClose: () => {
                clearInterval(timerInterval);
            }
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                navigate("/tramite/Historial-Flotillas")
            }
        });


        fetch(`${ENDPOINT}/completeness`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.flotillaComplete === false) {
                    Swal.close();
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: result.message,

                    });

                    /*   toast.update(idToast, { render: result.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, }); */
                }



            })
            .catch(error => console.log('error', error));


    }
    const ValidarAuto = (data, data2) => {
        const idToast = toast.loading("Verificando Número de Serie...");

        // Devuelve una nueva promesa
        return new Promise((resolve, reject) => {
            if (data < 1900) {
                toast.update(idToast, { render: "Favor de verificar el modelo ingresado.", type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });
                reject(new Error("Modelo ingresado inválido"));
            } else {
                var myHeaders = new Headers();
                myHeaders.append("Authorization", token);
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    serie: data2,
                    modelo: data,
                });

                var requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow",
                };

                fetch(`${ENDPOINT}/check-vehicle`, requestOptions)
                    .then((response) => response.json())
                    .then((result) => {
                        setLoading(false);

                        if (result.exists === true) {
                            toast.update(idToast, { render: result.message, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });
                            resolve(result); // Resuelve la promesa con el resultado
                        } else if (result.error !== undefined) {
                            toast.update(idToast, { render: result.error, type: "error", isLoading: false, autoClose: 3000, closeOnClick: true, });
                            resolve(result); // Resuelve la promesa con el resultado
                        } else {
                            toast.dismiss(idToast.current);
                            resolve(result); // Resuelve la promesa con el resultado
                        }
                    })
                    .catch((error) => {
                        console.log("error", error);
                        reject(error); // Rechaza la promesa con el error
                    });
            }
        });
    };

    const handleInputChangeCodigo = (e) => {
        const { name, value } = e.target;
        const numericValue = value.replace(/\D/g, "");
        if (numericValue.length <= 4) {
            formikSelect.setFieldValue(name, numericValue);
        }
    };
    const handleChange = event => {
        const { name, value } = event.target;
        const uppercaseValue = value.toUpperCase();

        formikSelect.setFieldValue(name, uppercaseValue);
    };
    const headerRef = useRef(null);
    const [secondHeaderTop, setSecondHeaderTop] = useState(0);

    useEffect(() => {
        const adjustHeaderPosition = () => {
            if (headerRef.current) {
                const headerHeight = headerRef.current.getBoundingClientRect().height;
                setSecondHeaderTop(headerHeight);
            }
        };

        adjustHeaderPosition(); // Initial adjustment
        window.addEventListener('resize', adjustHeaderPosition); // Adjust on window resize

        return () => {
            window.removeEventListener('resize', adjustHeaderPosition);
        };
    }, []);
    return (
        <>
            <div className="HeaderTramite">
                <View className="FormTitle">Emplacamiento Exprés</View>
                <View className="FormSubTitle">Registro de Vehículos</View>
                <View className="FormDescripcion"></View>
                <View className="FormDescripcionFlotillas">
                    <div className="InfoFlotillas">
                        <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <strong>RFC:</strong>
                            </span>

                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <strong>{perData?.Item?.tipoPersonalidad.S === "Moral" ? "Razón social:" : "Nombre:"}</strong>{" "}
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                <strong>Estado:</strong>{" "}
                            </span>
                            {/*  <span style={{ display: "flex", justifyContent: "center" }}>
                                <strong>Tipo de Facturación:</strong>{" "}
                            </span> */}
                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "1fr" }}>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                {location.state?.ticket?.rfc ? location.state?.ticket?.rfc : location.state?.rfc.S}
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                {perData?.Item.razon_social.S}{" "}
                            </span>
                            <span style={{ display: "flex", justifyContent: "center" }}>
                                {perDataTicket?.status?.S}
                                {/*getIconStatus(location.state.status.S)}
                                <HelpOutlineIcon
                                    style={{ padding: "5px" }}
                                    className="cursor- cursor-pointer"
                                    onClick={() => setHelpView(true)}
                                />{" "*/}
                            </span>
                            {/*     <span style={{ display: "flex", justifyContent: "center" }}>
                                <Form noValidate>

                                    <Form.Group as={Col}>

                                        <Form.Check
                                            type="switch"
                                            id="facturacion"
                                            label={formikSelect.values.facturacion === false ? "Por Flotilla" : "Por Vehículo"}
                                            checked={formikSelect.values.facturacion}
                                            onChange={(e) => {
                                                formikSelect.handleChange(e)
                                                handleChangeSelect(e)
                                            }}
                                        />
                                    </Form.Group>
                                </Form>

                            </span> */}

                        </div>
                    </div>
                </View>

            </div>
            <Fila className="mb-0">
                <Form.Group as={Col} md="3" style={{ textAlign: "left" }}>
                    <button type="button" onClick={() => navigate("/tramite/Historial-Flotillas")}
                        className="text-black  font-medium rounded-lg text-base text-left inline-flex items-center ">
                        <FaArrowLeft /> Volver al Inicio
                    </button>

                </Form.Group>

                <Form.Group as={Col} md="7">


                </Form.Group>
            </Fila>
            <Fila className="mb-0">
                <Form.Group as={Col} md="2" style={{ textAlign: "left" }}>

                </Form.Group>

                <Form.Group as={Col} md="3"></Form.Group>
                <Form.Group as={Col} md="7" style={{ textAlign: "right" }}>
                    <div style={{ display: "flex", justifyContent: "end", alignItems: "center", gap: "2px" }}>
                        <Tooltip title="Actualiza la informacion de la página" followCursor>
                            <IconButton onClick={() => setChangeInfo(!changeInfo)}>
                                <TfiReload />
                            </IconButton>
                        </Tooltip>
                        <Button disabled={perDataTicket?.status?.S=== "Plantilla" ? true: false} onClick={() => setShow(true)} variant="secondary" style={{ textAlign: "right" }}><div style={{ display: "flex", alignItems: "center" }}>
                            Subir CSV <SiMicrosoftexcel style={{ marginLeft: "5px" }} /></div></Button>
                        <Button href="https://alta-vehicular-plantilla-csv.s3.amazonaws.com/Plantilla+Veh%C3%ADculos+Emplacamiento+Expr%C3%A9s.csv" variant="secondary">Descargar Plantilla</Button>
                        <HelpOutlineIcon
                            style={{ padding: "5px", textAlign: "left", width: "30px", height: "30px" }}
                            className="cursor- cursor-pointer"
                            onClick={() => setHelpView(true)}
                        />
                        {selectedOptions.length !== 0 &&

                            <Tooltip title="Eliminar Vehículos a la flotilla" followCursor>
                                <IconButton disabled={perDataTicket?.status?.S=== "Plantilla" ? true: false} onClick={() => Swal.fire({
                                    title: "¿Desea eliminar los vehículos registrados?",
                                    showDenyButton: true,
                                    showCancelButton: false,
                                    confirmButtonText: "Si",
                                    denyButtonText: `No`,
                                    confirmButtonColor: "#6c757d",


                                }).then((result) => {
                                    /* Read more about isConfirmed, isDenied below */
                                    if (result.isConfirmed) {
                                        EliminarVehiculos()
                                    } else if (result.isDenied) {

                                    }
                                })}>
                                    <FaRegTrashCan />
                                </IconButton>
                            </Tooltip>
                        }

                    </div>

                </Form.Group>


            </Fila>
            <View className="ViewPrincipal">
                <View className="ViewContent">
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>

                        <TableContainer sx={{ maxHeight: 600 }} >
                            <Table stickyHeader aria-label="sticky table" >
                                <TableHead >
                                    <TableRow ref={headerRef}>

                                        <TableCell align="center" style={{ position: "sticky", left: "0", backgroundColor: "white" }}>
                                            <Form.Check
                                                type="checkbox"
                                                label="No."
                                                disabled={rows.length === 0 ? true : false || perDataTicket?.status?.S=== "Plantilla" ? true: false}
                                                checked={selectAll}
                                                onChange={handleSelectAllChange}
                                            />
                                        </TableCell>
                                        <TableCell align="center">Número de Serie</TableCell>
                                        <TableCell align="center">Confirmar Número de Serie</TableCell>
                                        <TableCell align="center">Modelo</TableCell>
                                        <TableCell align="center" style={{ minWidth: "10rem" }}>Estado de Procedencia</TableCell>
                                        <TableCell align="center" style={{ minWidth: "10rem" }}>Origen del Vehículo</TableCell>
                                        <TableCell align="center">Decreto de regularización o repuve</TableCell>
                                        <TableCell align="center">Hoja de recepción de información</TableCell>
                                        <TableCell align="center">Titulo Americano</TableCell>
                                        <TableCell align="center">Factura</TableCell>
                                        <TableCell align="center">Acciones</TableCell>
                                    </TableRow>
                                    <TableRow onSubmit={() => formikSelect.handleSubmit()}>
                                        <TableCell align="center" style={{ position: "sticky", left: "0", backgroundColor: "white", top: secondHeaderTop }}>


                                        </TableCell>
                                        <TableCell style={{ top: secondHeaderTop }} align="center">
                                            <Form.Group
                                                as={Col}
                                                className="position-relative"
                                            >


                                                <Form.Control
                                                    type="text"
                                                    autoComplete="off"
                                                    name={`NoSerie`}

                                                    maxLength="17"
                                                    placeholder="No. Serie"
                                                    value={formikSelect.values.NoSerie}
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        formikSelect.setFieldTouched("NoSerie", true, false)
                                                    }}
                                                    isValid={
                                                        formikSelect.touched.NoSerie &&
                                                        !formikSelect.errors.NoSerie
                                                    }
                                                    isInvalid={

                                                        formikSelect.touched.NoSerie &&
                                                        !!formikSelect.errors.NoSerie
                                                    }
                                                />

                                                <Form.Control.Feedback type="invalid" tooltip>{formikSelect.errors.NoSerie}</Form.Control.Feedback>
                                            </Form.Group></TableCell>
                                        <TableCell style={{ top: secondHeaderTop }} align="center">

                                            <Form.Group
                                                as={Col}

                                                className="position-relative"
                                            >


                                                <Form.Control
                                                    type="text"
                                                    autoComplete="off"
                                                    name={`ConfirmarNoSerie`}
                                                    placeholder="No. Serie"
                                                    maxLength="17"
                                                    value={
                                                        formikSelect.values.ConfirmarNoSerie
                                                    }
                                                    onChange={(e) => {
                                                        handleChange(e)
                                                        formikSelect.setFieldTouched("ConfirmarNoSerie", true, false)
                                                    }}
                                                    isValid={
                                                        formikSelect.touched.ConfirmarNoSerie &&
                                                        !formikSelect.errors.ConfirmarNoSerie
                                                    }
                                                    isInvalid={
                                                        formikSelect.touched.ConfirmarNoSerie &&
                                                        !!formikSelect.errors.ConfirmarNoSerie
                                                    }
                                                />

                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    {formikSelect.errors.ConfirmarNoSerie}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </TableCell>
                                        <TableCell style={{ top: secondHeaderTop }} align="center">
                                            <Form.Group as={Col} className="position-relative">

                                                <Form.Control
                                                    type="text"
                                                    name={`Modelo`}
                                                    placeholder="Modelo"
                                                    autoComplete="off"
                                                    value={formikSelect.values.Modelo}
                                                    onChange={handleInputChangeCodigo}
                                                    isValid={
                                                        formikSelect.touched.Modelo &&
                                                        !formikSelect.errors.Modelo
                                                    }
                                                    isInvalid={
                                                        formikSelect.touched.Modelo &&
                                                        !!formikSelect.errors.Modelo
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    {formikSelect.errors.Modelo}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </TableCell>
                                        <TableCell align="center" style={{ minWidth: "10rem", top: secondHeaderTop }}>
                                            <Form.Group
                                                as={Col}
                                                className="position-relative"
                                            >


                                                <Select
                                                    classNamePrefix="custom-select"
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                    }}
                                                    name="EstadoPro"
                                                    value={estadosArray.find(option => option.value === formikSelect.values.EstadoPro) || null}
                                                    onChange={option => formikSelect.setFieldValue("EstadoPro", option ? option.value : "")}
                                                    onBlur={formikSelect.handleBlur}
                                                    isValid={formikSelect.touched.EstadoPro && !formikSelect.errors.EstadoPro}
                                                    isInvalid={formikSelect.touched.EstadoPro && !!formikSelect.errors.EstadoPro}
                                                    options={estadosArray}
                                                    isSearchable={true}
                                                    placeholder="Estado de Procedencia"
                                                    className={
                                                        formikSelect.touched.EstadoPro && formikSelect.errors.EstadoPro ? 'is-invalid' : formikSelect.touched.EstadoPro ? 'is-valid' : ''
                                                    }
                                                />
                                                {formikSelect.touched.EstadoPro && formikSelect.errors.EstadoPro && (
                                                    <Form.Control.Feedback type="invalid" tooltip>
                                                        {formikSelect.errors.EstadoPro}
                                                    </Form.Control.Feedback>
                                                )}



                                            </Form.Group>
                                        </TableCell>
                                        <TableCell align="center" style={{ minWidth: "10rem", top: secondHeaderTop }}>
                                            <Form.Group
                                                as={Col}
                                                className="position-relative"
                                            >


                                                <Form.Select
                                                    type="text"
                                                    placeholder="Estado de Procedencia"
                                                    name={`OrigenVehiculo`}
                                                    value={formikSelect.values.OrigenVehiculo}



                                                    onChange={formikSelect.handleChange}
                                                    autoComplete="off"
                                                    isValid={
                                                        formikSelect.touched.OrigenVehiculo &&
                                                        !formikSelect.errors.OrigenVehiculo
                                                    }
                                                    isInvalid={
                                                        formikSelect.touched.OrigenVehiculo &&
                                                        !!formikSelect.errors.OrigenVehiculo
                                                    }
                                                >
                                                    <option value="">Seleciona</option>
                                                    <option value="NACIONAL">NACIONAL</option>
                                                    <option value="EXTRANJERO">EXTRANJERO</option>

                                                </Form.Select>

                                                <Form.Control.Feedback type="invalid" tooltip>
                                                    {formikSelect.errors.OrigenVehiculo}
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </TableCell>
                                        {formikSelect.values.OrigenVehiculo === "EXTRANJERO" ?
                                            <>
                                                <TableCell style={{ top: secondHeaderTop }}>
                                                    <Form.Group
                                                        as={Col}

                                                        className="position-relative"
                                                    >


                                                        <>
                                                            <Form.Control
                                                                type="file"
                                                                placeholder="Decreto de regularización o repuve"
                                                                accept=".pdf"
                                                                name="Repuve"
                                                                autoComplete="off"
                                                                onChange={(event) => {
                                                                    formikSelect.setFieldValue(
                                                                        `Repuve`,
                                                                        event.currentTarget.files[0]
                                                                    );
                                                                }}

                                                                isInvalid={
                                                                    formikSelect.touched.Repuve &&
                                                                    !!formikSelect.errors.Repuve
                                                                }

                                                            />
                                                            <Form.Control.Feedback type="invalid" tooltip>{formikSelect.errors.Repuve}</Form.Control.Feedback>
                                                        </>

                                                    </Form.Group>
                                                </TableCell>
                                                <TableCell style={{ top: secondHeaderTop }}>
                                                    <Form.Group
                                                        as={Col}
                                                        className="position-relative"
                                                    >


                                                        <Form.Control
                                                            type="file"
                                                            placeholder="HojaRecepcion"
                                                            accept=".pdf"
                                                            name={`HojaRecepcion`}
                                                            autoComplete="off"
                                                            onChange={(event) => {
                                                                formikSelect.setFieldValue(
                                                                    `HojaRecepcion`,
                                                                    event.currentTarget.files[0]
                                                                );
                                                            }}

                                                            isInvalid={
                                                                formikSelect.touched.HojaRecepcion &&
                                                                !!formikSelect.errors.HojaRecepcion
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid" tooltip>{formikSelect.errors.HojaRecepcion}</Form.Control.Feedback>


                                                    </Form.Group>
                                                </TableCell>

                                                <TableCell style={{ top: secondHeaderTop }}>
                                                    <Form.Group
                                                        as={Col}

                                                        className="position-relative"
                                                    >


                                                        <Form.Control
                                                            type="file"
                                                            placeholder="Factura"
                                                            accept=".pdf"
                                                            name={`tituloAmericano`}
                                                            autoComplete="off"
                                                            onChange={(event) => {
                                                                formikSelect.setFieldValue(
                                                                    `tituloAmericano`,
                                                                    event.currentTarget.files[0]
                                                                );
                                                            }}

                                                            isInvalid={
                                                                formikSelect.touched.tituloAmericano &&
                                                                !!formikSelect.errors.tituloAmericano
                                                            }
                                                        />
                                                        <Form.Control.Feedback type="invalid" tooltip>{formikSelect.errors.tituloAmericano}</Form.Control.Feedback>

                                                    </Form.Group>
                                                </TableCell>
                                            </>
                                            :
                                            <>
                                                <TableCell style={{ top: secondHeaderTop }}></TableCell>
                                                <TableCell style={{ top: secondHeaderTop }}></TableCell>
                                                <TableCell style={{ top: secondHeaderTop }}></TableCell>
                                            </>
                                        }
                                        {formikSelect.values.OrigenVehiculo === "NACIONAL" ?
                                            <TableCell style={{ top: secondHeaderTop }}>
                                                <Form.Group
                                                    as={Col}

                                                    className="position-relative"
                                                >


                                                    <Form.Control
                                                        type="file"
                                                        placeholder="Factura"
                                                        accept=".pdf"
                                                        name={`Factura`}

                                                        autoComplete="off"
                                                        onChange={(event) => {

                                                            formikSelect.setFieldValue(
                                                                `Factura`,
                                                                event.currentTarget.files[0]
                                                            );


                                                        }}

                                                        isInvalid={
                                                            formikSelect.touched.Factura &&
                                                            !!formikSelect.errors.Factura
                                                        }
                                                    />
                                                    <Form.Control.Feedback type="invalid" tooltip>{formikSelect.errors.Factura}</Form.Control.Feedback>

                                                </Form.Group>
                                            </TableCell>
                                            :
                                            <TableCell style={{ top: secondHeaderTop }}></TableCell>
                                        }
                                        <TableCell align="center" style={{ minHeight: "89px", top: secondHeaderTop }}>
                                            <div style={{ display: "flex" }}>
                                                <Tooltip title="Agrega el Vehículo a la flotilla" followCursor>
                                                    <IconButton disabled={perDataTicket?.status?.S=== "Plantilla" ? true: false} onClick={() => formikSelect.handleSubmit()}>
                                                        <IoIosAddCircleOutline />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Limpia el formulario" followCursor>
                                                    <IconButton disabled={perDataTicket?.status?.S=== "Plantilla" ? true: false} onClick={() => formikSelect.resetForm()} >
                                                        <PiBroom />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </TableCell>
                                    </TableRow>

                                </TableHead>

                                <TableBody>
                                    {rows
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <Row key={index} perDataTicket={perDataTicket} rowsPerPage={rowsPerPage} nuevoRegistroRef={nuevoRegistroRef} token={token} data={row} index={index} changeInfo={changeInfo} handleCheckboxChange={handleCheckboxChange} handleSelectAllChange={handleSelectAllChange} selectedOptions={selectedOptions} setChangeInfo={setChangeInfo} />
                                        ))}
                                </TableBody>
                            </Table>
                            {perDataTicket?.cantidadVehiculos?.N !== "0" && perDataTicket?.cantidadVehiculos?.N === perDataTicket?.completeVehiculoCounter?.N &&

                                <Button onClick={() => handleTerminarFlotilla()} className='mt-3' variant='secondary'>Comenzar proceso de acreditación
                                </Button>

                            }
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[100]}
                            component="div"
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage="Filas por página:"
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Paper>
                </View>
            </View >
            <Modal show={helpView} animation={false} onHide={() => setHelpView(false)} centered size='lg'>
                <Modal.Header >
                    <Modal.Title>Carga de vehículos por plantilla CSV</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Estimado contribuyente, también puede cargar la información de sus vehículos mediante un documento de plantilla CSV: {<a rel='noopener noreferrer' href="https://alta-vehicular-plantilla-csv.s3.amazonaws.com/Plantilla+Veh%C3%ADculos+Emplacamiento+Expr%C3%A9s.csv">Descargar</a>}.<br /><br /> */}
                    Por favor utilice el código de tres caracteres del ISO 3166-2 para especificar el estado de procedencia de sus vehículos {<a rel='noopener noreferrer' target="_blank" href="https://es.wikipedia.org/wiki/Anexo:Abreviaturas_en_M%C3%A9xico">Ver</a>}.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setHelpView(false)}>
                        Aceptar
                    </Button>
                </Modal.Footer>
            </Modal>
            <div>
                <Backdrop
                    sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}
                >
                    <h1>Esperando Respuesta</h1>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
            <ModalCSV show={show} setShow={setShow} location={perDataTicket} rowsLength={rows.length} changeInfo={changeInfo} setChangeInfo={setChangeInfo} />
            <ToastContainer closeOnClick />
        </>
    );
};
