import React, { useEffect, useState } from "react";

// Tu función asíncrona
const ObtenerDatosSelect = async (params = {})=> {
    const { id, valor = "dominioId" } = params;
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        [valor]: id
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
    };

    try {
        const response = await fetch("https://zpsf1razwi.execute-api.us-east-1.amazonaws.com/test/catalog/queryCategoryItems", requestOptions);
        const result = await response.json();
        if (Array.isArray(result.items)) {
            return result.items;
        } else {
            console.error("result.items is not an array:", result.items);
            return [];
        }
    } catch (error) {
        console.error(error);
        return [];
    }
};

const ObtenerDescripciones = (props) => {
    const [items, setItems] = useState([]);
    const {id, valor,campo, tipo} = props;
    function buscarPorValor(array, valorBuscado) {
        // Encuentra el objeto en el array que coincide con el valorBuscado
        const objetoEncontrado = array.find(item => item["ID"] === valorBuscado);
        
        // Devuelve el valor específico del campoADevolver si el objeto fue encontrado
        return objetoEncontrado ? objetoEncontrado["DESCRIPCION"] : null;
      }

    useEffect(() => {
        const fetchData = async () => {
            const data = await ObtenerDatosSelect({id, valor}); // Llamas a la función con el id pasado como prop
            setItems(data); // Actualizas el estado con los datos obtenidos
        };

        fetchData();
    }, [id]); // Se vuelve a ejecutar cuando `id` cambia

    return (
        <>
         {tipo === "select"?
            <option value="">{buscarPorValor(items,campo)}</option>
         :

             <label>{buscarPorValor(items, campo)}</label>
         }
          
        </>
    );
};

export default ObtenerDescripciones;
