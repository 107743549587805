import React, { useState } from 'react'
import { Button, Form, Row, Col } from 'react-bootstrap'
import { View } from '@aws-amplify/ui-react'
import { Formik, useField } from 'formik';
import * as yup from 'yup';
import { FaArrowLeft } from "react-icons/fa";
import { toast, ToastContainer } from 'react-toastify';
import { CrearIteamAPI } from '../../api/Licencias';
import { getCurrentUser } from 'aws-amplify/auth';
import OpcionesSelect from './OpcionesSelect';
import MaskedInput from 'react-text-mask';
import { PhoneNumberField } from '@aws-amplify/ui-react';
import Swal from 'sweetalert2';
import { Backdrop, CircularProgress } from '@mui/material';
export const FormDatosEmergencia = (props) => {

    const { datos, setChangeData, changeData } = props;
    const [editar, setEditar] = useState(datos.contactoEmergencia !== null ? true : false);
    const dialCodeRef = React.useRef(null);
    const [loadingSave, setLoadingSave] = useState(false);
    const schema = yup.object().shape({

        nombreResponsable: yup.string().required("Este Campo es Obligatorio"),
        domicilio: yup.string().required("Este valor es Obligatorio"),
        estado: yup.string().required("Este valor es Obligatorio"),
        otp: yup.string(),
        municipio: yup.string().required("Este valor es Obligatorio"),
        telefono: yup.string().required("Este valor es Obligatorio").matches(/^(\(\d{3}\) \d{3}-\d{4}|\d{10})$/, 'Formato no valido')
    });
    const handleInputChange = (e, setFieldValue) => {
        const { name, value } = e.target;
        let values = value.toUpperCase();
        if (name === "telefono" || name === "otp") {
            values = values.replace(/[^0-9]/g, '');

            setFieldValue(name, values);
        } else if (name === "domicilio") {

            values = values.replace(/[^A-Z0-9ÁÉÍÓÚÑÜ\s'.,-]/g, '');

            setFieldValue(name, values);
        } else {
            values = values.replace(/[^A-ZÁÉÍÓÚÑÜ\s'.,-]/g, '');

            setFieldValue(name, values);
        }
    };

    const VerificarOTP = (otp) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "procedureType": datos.procedureType,
            "id": datos.id,
            "phoneNumber": datos.contactoEmergencia.telefono,
            "otp": otp,
            "sectionName": "contactoEmergencia",
        });
        console.log(raw)
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://zpsf1razwi.execute-api.us-east-1.amazonaws.com/test/otp/verify", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if (result.verified === true) {
                    toast.success(result.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    setChangeData(!changeData)
                } else {
                    toast.error(result.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }

            })
            .catch((error) => console.error(error));
    }
    const ObtenerOTP = (number, reenviar) => {
        const cleanedPhoneNumber = number.replace(/[^\d]/g, '');
        const telefono = `${dialCodeRef.current.value}${cleanedPhoneNumber}`
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "procedureType": datos.procedureType,
            "id": datos.id,
            "phoneNumber": reenviar === true ? number : telefono,
            "sectionName": "contactoEmergencia"
        })
        console.log(raw)
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://zpsf1razwi.execute-api.us-east-1.amazonaws.com/test/otp", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if (result.error === undefined) {
                    setChangeData(!changeData)
                    Swal.fire({
                        text: "Se ha enviado un número de verificacíon al número de telefono, favor de ingresarlo para verificar el número ",
                        icon: "success",
                        confirmButtonColor: "#607472",
                        confirmButtonText: "Aceptar"
                    }).then((result) => {
                        if (result.isConfirmed) {
                        }
                    });
                } else {
                    toast.error(result.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                }
            })
            .catch((error) => console.error(error));
    }
    const CustomPhoneNumberField = ({ name, disabled, touched, errors }) => {
        const [field, meta, helpers] = useField(name);
        const { setValue } = helpers;
        const handlePhoneChange = (e) => {
            setValue(e.target.value);
        };
        return (
            <MaskedInput
                mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholder="(999) 999-9999"
                value={field.value}
                onChange={handlePhoneChange}
                render={(inputRef, props) => (
                    <PhoneNumberField
                        {...props}
                        defaultDialCode={datos?.contactoEmergencia?.codigoPais !== undefined ? datos.contactoEmergencia.codigoPais : "+52"}
                        dialCodeRef={dialCodeRef}
                        isDisabled={disabled}
                        ref={inputRef} // <-- Aquí cambiamos inputRef por ref
                        hasError={meta.touched && !!meta.error}
                        className={` ${touched.telefono && errors.telefono ? 'is-invalid' : touched.telefono ? 'is-valid' : ''}`}
                    />
                )}
            />
        );
    };
    return (
        <>


            <View className="ViewPrincipal">

                <View className="ViewContent">
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{

                            nombreResponsable: datos.contactoEmergencia !== null ? datos.contactoEmergencia.nombre : "",
                            domicilio: datos.contactoEmergencia !== null ? datos.contactoEmergencia.domicilio : "",
                            estado: datos.contactoEmergencia !== null ? datos.contactoEmergencia.estado : "",
                            municipio: datos.contactoEmergencia !== null ? datos.contactoEmergencia.municipio : "",
                            telefono: datos.contactoEmergencia !== null ? datos.contactoEmergencia.telefono : "",
                            otp: ""
                        }}
                        onSubmit={async values => {
                            console.log(values);
                            setLoadingSave(true)
                            const cleanedPhoneNumber = values.telefono.replace(/[^\d]/g, '');
                            const telefono = `${dialCodeRef.current.value}${cleanedPhoneNumber}`
                            const user = await getCurrentUser();
                            const data = {
                                "id": datos.id,
                                "procedure": datos.procedure,
                                "type": datos.type,
                                "usuarioID": user.username,
                                "contactoEmergencia": {
                                    "nombre": values.nombreResponsable,
                                    "domicilio": values.domicilio,
                                    "estado": values.estado,
                                    "municipio": values.municipio,
                                    "telefono": cleanedPhoneNumber,
                                    "codigoPais": dialCodeRef.current.value,
                                    "isComplete": true
                                },
                            }
                            CrearIteamAPI(data).then(result => {
                                if (result.ok) {
                                    toast.success("Datos Guardados Correctamente", {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                    setEditar(true)
                                    setChangeData(!changeData)
                                    /*   if (datos.contactoEmergencia === null || datos.contactoEmergencia.telefono !== telefono) {
                                          ObtenerOTP(values.telefono, false);
                                      } */
                                } else {
                                    toast.error(result.message, {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });

                                }
                                setLoadingSave(false)
                            }).catch(error => {
                                setLoadingSave(false)
                                console.log(error)
                            })
                        }}
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue, resetForm }) => (
                            <Form onSubmit={handleSubmit} noValidate style={{ textAlign: "left" }}>

                                <Row className='mb-3'>
                                    <Form.Label style={{ textDecoration: "underline" }}>En caso de emergencia llamar a:</Form.Label>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Nombre Completo*</Form.Label>
                                        <Form.Control
                                            name='nombreResponsable'
                                            type='text'
                                            placeholder='Nombre Completo'
                                            value={values.nombreResponsable}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={editar && datos?.contactoEmergencia?.isComplete === true}
                                            isValid={touched.nombreResponsable && !errors.nombreResponsable}
                                            isInvalid={touched.nombreResponsable && !!errors.nombreResponsable}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.nombreResponsable}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Domicilio*</Form.Label>

                                        <Form.Control
                                            name='domicilio'
                                            type='text'
                                            placeholder='Domicilio'
                                            value={values.domicilio}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={editar && datos?.contactoEmergencia?.isComplete === true}
                                            isValid={touched.domicilio && !errors.domicilio}
                                            isInvalid={touched.domicilio && !!errors.domicilio}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.domicilio}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Estado*</Form.Label>

                                        <Form.Control
                                            name='estado'
                                            type='text'
                                            placeholder='Estado'
                                            value={values.estado}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={editar && datos?.contactoEmergencia?.isComplete === true}
                                            isValid={touched.estado && !errors.estado}
                                            isInvalid={touched.estado && !!errors.estado}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.estado}</Form.Control.Feedback>


                                    </Form.Group>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Municipio*</Form.Label>

                                        <Form.Control
                                            name='municipio'
                                            type='text'
                                            placeholder='Municipio'
                                            value={values.municipio}
                                            onChange={(event) => handleInputChange(event, setFieldValue)}
                                            disabled={editar && datos?.contactoEmergencia?.isComplete === true}
                                            isValid={touched.municipio && !errors.municipio}
                                            isInvalid={touched.municipio && !!errors.municipio}
                                        />
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.municipio}</Form.Control.Feedback>


                                    </Form.Group>
                                </Row>
                                <Row className='mb-3'>
                                    <Form.Group as={Col} className='position-relative'>
                                        <Form.Label>Telefono*</Form.Label>
                                        <CustomPhoneNumberField name="telefono" disabled={editar && datos?.contactoEmergencia?.isComplete === true} touched={touched} errors={errors} />
                                        {/*     <MaskedInput
                                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                                            placeholder="(999) 999-9999"
                                            value={values.telefono}
                                            onChange={(event) => setFieldValue('telefono', event.target.value)}
                                            disabled={editar}
                                            className={`form-control ${touched.telefono && errors.telefono ? 'is-invalid' : touched.telefono ? 'is-valid' : ''}`}
                                        /> */}
                                        <Form.Control.Feedback type='invalid' tooltip>{errors.telefono}</Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                {/*  {datos?.contactoEmergencia?.otpData !== undefined && datos.contactoEmergencia.otpData.verificado === false &&

                                    <Row>
                                        <Form.Group as={Col}>
                                            <Form.Label>Ingrese el codigo de verificacíon</Form.Label>

                                            <Form.Control
                                                type='text'
                                                name="otp"
                                                value={values.otp}
                                                onChange={(event) => handleInputChange(event, setFieldValue)}
                                            />

                                            <Button className='m-1' variant='secondary' onClick={() => ObtenerOTP(datos.contactoEmergencia.telefono, true)}>Reenviar Código</Button>
                                            <Button className='m-1' variant='secondary' onClick={() => VerificarOTP(values.otp)}>Verificar</Button>
                                        </Form.Group>
                                    </Row>
                                } */}
                                {datos.estatus === "Captura" || datos.estatus === "Rechazado" ?
                                    <>
                                        {datos.type !== 234 &&

                                            <Row className='mb-3'>
                                                <Form.Group as={Col} className='d-grid'>
                                                    {editar === false && datos.contactoEmergencia !== null ?

                                                        <Button variant='secondary' onClick={() => {
                                                            setEditar(true)
                                                            resetForm()
                                                        }} >CANCELAR</Button>
                                                        :
                                                        <Button variant='secondary' disabled={datos.contactoEmergencia !== null ? false : true} onClick={() => setEditar(false)} >EDITAR</Button>
                                                    }
                                                </Form.Group>
                                                <Form.Group as={Col} className='d-grid'>

                                                    <Button variant='secondary' disabled={editar && datos?.contactoEmergencia?.isComplete === true} type='submit'>GUARDAR</Button>
                                                </Form.Group>
                                            </Row>
                                        }
                                    </>
                                    : null
                                }
                            </Form>
                        )}
                    </Formik>
                </View>
            </View>
            <ToastContainer />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loadingSave}

            >
                <h1>Guardando...</h1>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}
