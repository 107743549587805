import React from 'react'
import { View } from "@aws-amplify/ui-react";
import { Formik } from 'formik';
import * as yup from 'yup';
import { Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Swal from "sweetalert2";
const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/gif"
];

export default function CompareFaces(props) {
    const { sessionId } = props;
    const schema = yup.object().shape({
        imagen: yup
            .mixed()
            .required("Este campo es obligatorio")
            .test('fileType', 'Solo se permiten imágenes', function (value) {
                if (!value) {
                    return true; // Permitir si no hay archivo
                }
                return SUPPORTED_FORMATS.includes(value.type);
            })
            .test('fileSize', 'El archivo no debe ser mayor a 10 MB', function (value) {
                if (!value) {
                    return true; // Permitir si no hay archivo
                }

                const maxSize = 10 * 1024 * 1024; // 4 MB en bytes
                return value.size <= maxSize;
            }),
    });

    const SubirImagen = (fileImage) => {
        console.log(fileImage)
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "image/jpeg");

        const file = fileImage.imagen;

        const requestOptions = {
            method: "PUT",
            headers: myHeaders,
            body: file,
            redirect: "follow"
        };

        fetch(`https://f3eptptgqh.execute-api.us-east-1.amazonaws.com/test/${sessionId}.jpg`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if(result.s3_url !== undefined){
                    CompararImagenes(fileImage.name)
                }
            })
            .catch((error) => console.error(error));
    }

    const CompararImagenes = (name) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "source_key": `face-liveness-sessions/${sessionId}/reference.jpg`,
            "target_key": `${sessionId}.jpg`
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch("https://8bu4k437o9.execute-api.us-east-1.amazonaws.com/test/compareFaces", requestOptions)
            .then((response) => response.json())
            .then((result) => {
                console.log(result)
                if(result.number_of_matches === 0){
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: "Las rostros son diferentes, suba otra imagen",
                        
                      }); 
                }else{
                    Swal.fire({
                        title: "Validado",
                        text: "Validacion correcta puede continuar con el tramite",
                        icon: "success"
                      });
                }
            })
            .catch((error) => console.error(error));
    }
    return (
        <>
                    <Formik
                        enableReinitialize={true}
                        validationSchema={schema}
                        initialValues={{
                            imagen: "",

                        }
                        }
                        onSubmit={(valores) => {
                            console.log("Valores", valores);
                            SubirImagen(valores)
                        }
                        }
                    >
                        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                            <Form onSubmit={handleSubmit} noValidate >
                                <Row className='m-3'>
                                    <Form.Group as={Col} className="position-relative ">
                                        <Form.Label>Subir imagen*</Form.Label>
                                        <InputGroup>
                                            <Form.Control
                                                required
                                                type="file"
                                                name="imagen"
                                                accept=".jpg"
                                                onChange={(event) => {

                                                    setFieldValue(
                                                        "imagen",
                                                        event.currentTarget.files[0]
                                                    );


                                                }}
                                                isValid={touched.imagen && !errors.imagen}
                                                isInvalid={touched.imagen && !!errors.imagen}
                                            />
                                            <Button type='submit' variant='secondary'>Subir</Button>
                                        </InputGroup>
                                        <Form.Control.Feedback type="invalid" tooltip>

                                            {errors.imagen}
                                        </Form.Control.Feedback>
                                        <Form.Text muted>
                                            Tamaño máximo 10 MB.
                                        </Form.Text>
                                    </Form.Group>
                                </Row>
                            </Form>
                        )}
                    </Formik>


       
            <ToastContainer closeOnClick />
        </>
    )
}
